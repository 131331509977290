<template>
  <v-app>
    <Header/>
    <SideMenu/>
    <v-main>
      <router-view/>
    </v-main>
    <AutoReloader/>
  </v-app>
</template>

<script>
import Header from './components/Header'
import SideMenu from './components/SideMenu'
import AutoReloader from './components/AutoReloader'

export default {
  name: 'App',
  components: {
    Header,
    SideMenu,
    AutoReloader,
  },
  data: () => ({
    //
  }),
}
</script>
