<template>
  <v-container>
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>入場許可車両 新規登録</v-toolbar-title>
      </v-toolbar>

      <!-- 登録フォーム -->
      <ValidationObserver v-slot="{ invalid }">
        <v-container>
          <v-row>
            <v-col cols="12" lg="6" class="py-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="店舗名"
                rules="required"
              >
                <v-autocomplete
                  :outlined="$store.getters.isAdminUser"
                  dense
                  label="店舗名"
                  :items="request_client_stores"
                  item-text="name"
                  item-value="id"
                  v-model="selectedRequestClientStore"
                  return-object
                  :disabled="!$store.getters.isAdminUser"
                  :error-messages="errors"
                ></v-autocomplete>
              </ValidationProvider>
            </v-col>
            <!-- 複数駐車場がある場合 -->
            <v-col v-if="selectedRequestClientStore && parkings.length > 2" cols="12" lg="6" class="py-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="対象駐車場"
                rules="required"
              >
                <v-select
                  outlined
                  dense
                  v-model="selectedParking"
                  return-object
                  :items="parkings"
                  item-text="PARKING_NAME"
                  item-value="PARKING_ID"
                  label="対象駐車場"
                  :error-messages="errors"
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
          <template v-if="selectedRequestClientStore">
            <!-- アラート通知車両情報 -->
            <v-row>
              <v-col cols="12" class="py-0">
                <small>入場許可車両情報を入力してください<span class="red--text">(必須)</span></small>
                <v-divider class="mt-0 mb-3"></v-divider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="地域"
                  rules="required|car_place"
                >
                  <v-text-field
                    outlined
                    dense
                    label="地域 (例: 旭川)"
                    v-model="carPlace"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="クラス"
                  rules="required|car_class"
                >
                  <v-text-field
                    outlined
                    dense
                    label="クラス (例: 500)"
                    v-model="carClass"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="かな"
                  rules="required|car_kana"
                >
                  <v-text-field
                    outlined
                    dense
                    label="かな (例: あ)"
                    v-model="carKana"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="4桁ナンバー"
                  rules="required|car_number"
                >
                  <v-text-field
                    outlined
                    dense
                    label="4桁ナンバー (例: 3, 88, 456)"
                    v-model="carNumber"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row v-if="selectedRequestClientStore && selectedRequestClientStore.address" class="mb-5">
              <v-spacer></v-spacer>
              <v-btn
                outlined
                small
                color="teal"
                @click.stop="inOutRecordsDialog = true"
              >
                利用履歴から車両を再検索
              </v-btn>
              <v-spacer></v-spacer>
            </v-row>

            <!-- 有効期限 -->
            <v-row>
              <v-col cols="12" class="py-0">
                <small>
                  有効期間の開始日・終了日を選択してください<span class="red--text">(開始日は必須)</span>
                </small>
                <v-divider class="mt-0 mb-3"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-0">
                <v-menu
                  v-model="showTargetStartDateSelector"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="開始日"
                      rules="required"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="selectedTargetStartDateFormatted"
                        label="開始日"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="selectedTargetStartDate"
                    :max="maxStartDate ? maxStartDate : ''"
                    no-title
                    locale="ja"
                    @input="showTargetStartDateSelector = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pl-0 py-0">
                <v-menu
                  v-model="showTargetEndDateSelector"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      clearable
                      outlined
                      dense
                      v-model="selectedTargetEndDateFormatted"
                      label="終了日"
                      prepend-icon="mdi-arrow-right"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectedTargetEndDate"
                    :min="minEndDate ? minEndDate : ''"
                    no-title
                    locale="ja"
                    @input="showTargetEndDateSelector = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <!-- 開始・終了時間 -->
            <v-row>
              <v-col cols="12" class="py-0">
                <small>
                  使用開始時間・使用終了時間を選択してください
                </small>
                <v-divider class="mt-0 mb-3"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-0">
                <v-menu
                  v-model="showTargetStartTimeSelector"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      clearable
                      outlined
                      dense
                      v-model="selectedTargetStartTime"
                      label="開始時間"
                      prepend-icon="mdi-calendar-clock"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="selectedTargetStartTime"
                    locale="ja"
                    format="24hr"
                    @input="showTargetStartTimeSelector = false"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pl-0 py-0">
                <v-menu
                  v-model="showTargetEndTimeSelector"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      clearable
                      outlined
                      dense
                      v-model="selectedTargetEndTime"
                      label="終了時間"
                      prepend-icon="mdi-arrow-right"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="selectedTargetEndTime"
                    locale="ja"
                    format="24hr"
                    @input="showTargetEndTimeSelector = false"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="備考"
                  :rules="`max:${noteLimit}`"
                >
                  <v-textarea
                    outlined
                    dense
                    v-model="note"
                    no-resize
                    rows="4"
                    label="備考"
                    :counter="noteLimit"
                    :error-messages="errors"
                  ></v-textarea>
                </ValidationProvider>
              </v-col>
            </v-row>
          </template>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="brue-gray" class="mr-2" to="/">戻る</v-btn>
          <v-btn small outlined color="primary" :disabled="invalid" @click.stop="dialog = true">登録内容確認</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </ValidationObserver>

      <!-- 確認フォーム -->
      <v-dialog
        v-model="dialog"
        max-width="500px"
        scrollable
      >
        <v-card>
          <v-card-title>登録内容確認</v-card-title>
          <v-divider class="my-0"></v-divider>
          <v-card-text>
            <v-row>
              <small class="my-5">以下の内容で登録します。よろしいですか？</small>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="店舗名"
                  :value="selectedRequestClientStore ? selectedRequestClientStore.name : ''"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col v-if="parkings.length > 2" cols="12" class="py-0">
                <v-text-field
                  label="対象駐車場"
                  v-model="selectedParking.PARKING_NAME"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="ナンバープレート情報"
                  v-model="numberInfo"
                  readonly
                ></v-text-field>
              </v-col>
              <template v-if="selectedTargetStartDateFormatted || selectedTargetEndDateFormatted">
                <v-col cols="6" class="py-0">
                  <v-text-field
                    v-model="selectedTargetStartDateFormatted"
                    label="開始日"
                    prepend-icon="mdi-calendar"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pl-0 py-0">
                  <v-text-field
                    v-model="selectedTargetEndDateFormatted"
                    label="終了日"
                    prepend-icon="mdi-arrow-right"
                    readonly
                  ></v-text-field>
                </v-col>
              </template>
              <v-col cols="6" class="py-0">
                <v-text-field
                  v-model="selectedTargetStartTime"
                  label="開始時間"
                  prepend-icon="mdi-calendar-clock"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  v-model="selectedTargetEndTime"
                  label="終了時間"
                  prepend-icon="mdi-arrow-right"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-textarea
                  v-model="note"
                  no-resize
                  rows="4"
                  label="備考"
                  readonly
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ma-0"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small outlined color="blue-grey" @click="dialog = false">修正する</v-btn>
            <v-btn small outlined color="primary" @click="save" :loading="isLoading">登録する</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <SearchInOutRecords
        :selectedRequestClientStore="selectedRequestClientStore"
        :inOutRecordsDialog="inOutRecordsDialog"
        searchFrom="alertVehicles"
        @applyRecord="applyRecord"
        @closeDialog="closeDialog"
      />
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment"
import qs from 'qs'
import SearchInOutRecords from '../../components/SearchInOutRecords'

export default {
  name: 'newGateControlVehicles',
  components: {
    SearchInOutRecords,
  },
  data: () => ({
    showTargetStartDateSelector: false,
    showTargetEndDateSelector: false,
    showTargetStartTimeSelector: false,
    showTargetEndTimeSelector: false,

    request_client_stores: [],
    selectedRequestClientStore: null,

    parkings: [],
    selectedParking: {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"},

    dialog: false,
    inOutRecordsDialog: false,

    // 登録フォーム
    carPlace: '',
    carClass: '',
    carKana: '',
    carNumber: '',
    selectedTargetStartDate: '',
    selectedTargetStartDateFormatted: '',
    selectedTargetEndDate: '',
    selectedTargetEndDateFormatted: '',
    selectedTargetStartTime: '',
    selectedTargetEndTime: '',
    note: '',
    registDte: null,

    isLoading: false,
  }),
  computed: {
    numberInfo () {
      return `${this.carPlace} ${this.carClass} ${this.carKana} ${this.carNumber}`
    },
    maxStartDate () {
      return this.selectedTargetEndDate ? moment(this.selectedTargetEndDate).add(1, 'days').format('yyyy-MM-DD') : null
    },
    minEndDate () {
      return this.selectedTargetStartDate ? moment(this.selectedTargetStartDate).add(1, 'days').format('yyyy-MM-DD') : null
    },
    noteLimit () {
      return 99
    }
  },
  watch: {
    selectedRequestClientStore (value) {
      if (value && value.address) {
        this.getParkings()
        this.selectedParking = {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"}
        this.inOutRecordsDialog = true
      }
    },
    selectedTargetStartDate (value) {
      if (value) { this.selectedTargetStartDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetEndDate (value) {
      if (value) { this.selectedTargetEndDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetStartDateFormatted (value) {
      if (!value) { this.selectedTargetStartDate = '' }
    },
    selectedTargetEndDateFormatted (value) {
      if (!value) { this.selectedTargetEndDate = '' }
    },
    selectedTargetStartTime (value) {
      if (!value) { this.selectedTargetStartTime = '' }
    },
    selectedTargetEndTime (value) {
      if (!value) { this.selectedTargetEndTime = '' }
    }
  },
  methods: {
    validateUserAccess() {
      if (!this.$store.getters.isAdminUser && !this.$store.getters.isGateControlStore) {
        this.$router.push({ path: '/' });
      }
    },
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
            this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
          }
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    getParkings () {
      const url = `${process.env.VUE_APP_BASE_URL}parkings?&host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}`
      this.axios
        .get(url)
        .then(response => {
          this.parkings = [{PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"}].concat(response.data)
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    // 入出庫一覧で選択したレコードの情報に置換
    applyRecord (record) {
      this.carPlace = record.PLACE
      this.carClass = record.CLASS_NUMBER
      this.carKana = record.KANA
      this.carNumber = record.CAR_NUMBER.replace(/\*/g,'')
      this.inOutRecordsDialog = false
    },
    // 入出庫一覧を閉じる
    closeDialog () {
      this.inOutRecordsDialog = false
    },
    // 登録処理
    async save () {
      this.isLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}gate_control_car_lists`
      let data = {
        place: this.carPlace,
        classNumber: this.carClass,
        kana: this.carKana,
        carNumber: ('****' + this.carNumber).slice(-4),
        note: this.note,
        startDte: moment(this.selectedTargetStartDate).format('YYYY-MM-DD HH:mm:ss'),
        endDte: this.selectedTargetEndDate ? moment(this.selectedTargetEndDate).format('YYYY-MM-DD HH:mm:ss') : moment('9999/12/31').format('YYYY-MM-DD HH:mm:ss'),
        startTime: this.selectedTargetStartTime ? moment(this.selectedTargetStartTime, 'HH:mm').format('HH:mm:ss') : '00:00:00',
        endTime: this.selectedTargetEndTime ? moment(this.selectedTargetEndTime, 'HH:mm').format('HH:mm:ss') : '00:00:00',
        request_client_store_id: this.selectedRequestClientStore.id,
        request_client_user_id: this.$store.getters.requestClientUserId,
        parkingId: this.selectedParking.PARKING_ID,
        address: this.selectedRequestClientStore.address
      }
      const options = {
        url: url,
        method: 'POST',
        data: qs.stringify(data),
      }
      await this.axios(options)
      .then(() => {
        this.$router.push({path: "/gate-control-vehicles"})
        this.$buefy.toast.open({
          message: `入場許可車両を登録しました`,
          type: "is-info"
        })
      })
      .catch(err => {
        console.log(err.response)
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else if (err.response && err.response.status == 406) {
          this.$buefy.toast.open({
            message: `staging環境もしくはローカル開発環境から本番環境への登録・更新操作は許可されていません`,
            type: "is-danger",
          })
        } else if (err.response && err.response.status == 409) {
          this.$buefy.toast.open({
            message: `この車両はすでに登録されています`,
            type: "is-danger",
          })
        } else {
          this.$buefy.toast.open({
            message: `入場許可車両の登録に失敗しました`,
            type: "is-danger",
          })
        }
        this.isLoading = false
        this.dialog = false
      })     
    },
  },
  mounted() {
    this.validateUserAccess()
    this.getRequestClientStores()
  },
}
</script>

<style scoped>
.list-content-style {
  white-space: pre-line;
}
.blur {
  position: relative;
  max-width: 100%;
  padding-bottom: 75%;
  background-size: cover;
  overflow: hidden;
}
.blur:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: inherit;
  background-clip: content-box;
  width: 100%;
  height: 100%;
  padding-bottom: 60%;
  filter: blur(5px);
}
</style>
