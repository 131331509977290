<template>
  <v-container>
    <v-data-table
      dense
      :headers="headers"
      :items="reasons"
      :items-per-page="15"
      :loading="loading"
      loading-text="Loading... Please wait"
      disable-sort
      sort-by="order"
      hide-default-footer
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>請求取消依頼理由一覧</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                outlined
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                新規追加
              </v-btn>
            </template>
            <v-card :loading="isFormLoading">
              <ValidationObserver ref="observer" v-slot="{ invalid }">
                <v-card-title>
                  <span class="text-h5">新規選択肢追加</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form ref="form">
                      <v-row>
                        <v-col cols="12">
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="依頼理由"
                            rules="required"
                          >
                            <v-text-field
                              dense
                              outlined
                              v-model="newReason"
                              label="依頼理由"
                              :error-messages="errors"
                            >
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" class="pt-0">
                          <small>新規選択肢を登録すると、直ちに請求取消依頼の理由を選択するプルダウンメニューへ反映されます。</small>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn small outlined color="blue-grey" @click="close">キャンセル</v-btn>
                  <v-btn small outlined color="primary" @click="save" :disabled="invalid">登録</v-btn>
                </v-card-actions>
              </ValidationObserver>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div v-if="item.id != 5">
          <v-btn v-if="item.order != 1" small color="teal" class="white--text font-weight-black mr-2" @click="swapOrder(item, 'up')">↑</v-btn>
          <v-btn v-if="item.order != reasons.length -1" small color="teal" class="white--text font-weight-black" @click="swapOrder(item, 'down')">↓</v-btn>
        </div>
        <div v-else>
          <v-btn small disabled>変更不可</v-btn>
        </div>
      </template>
      <template #[`item.is_hidden`]="{ item }">
        <span v-if="item.hidden">非表示</span>
        <span v-else>表示中</span>
        <v-btn v-if="item.id != 5" small :color="item.is_hidden? 'primary' : 'red'" class="white--text font-weight-black ml-4" @click="toggleVisibility(item)">
          {{ item.is_hidden? '表示する' : '非表示にする' }}
        </v-btn>
        <v-btn v-else class="ml-4" small disabled>変更不可</v-btn>
      </template>
    </v-data-table>
    <v-card class="mt-5">
      <v-card-title>デモ表示</v-card-title>
      <v-card-text>
        <div class="mb-10">
          <small>下枠内に現在の請求取消依頼画面の依頼理由選択部分の状態をデモで表示します。</small><br>
          <small>表示順変更や新規選択肢を追加するなどの操作後、確認のためにご利用ください。</small>
        </div>
        <v-select
          label="請求取消理由"
          outlined
          item-text="reason"
          persistent-placeholder
          placeholder="-- 選択してください --"
          :items="demoReasons"
        ></v-select>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import qs from 'qs'

export default {
  data: () => ({
    isFormLoading: false,
    dialog: false,
    loading: false,
    headers: [
      { text: '選択肢', value: 'reason' },
      { text: '表示順変更', value: 'actions', align: 'center' },
      { text: '表示／非表示', value: 'is_hidden' },
    ],
    reasons: [],
    demoReasons: [],
    newReason: '',
  }),
  methods: {
    async swapOrder (item, direction) {
      const url = process.env.VUE_APP_BASE_URL + "delete_request_reasons/" + item.id + "/swap_order"
      const data = {
        direction: direction,
      }
      const options = {
        url: url,
        method: 'PUT',
        data: qs.stringify(data),
      }
      await this.axios(options)
      .then(() => {
        this.getReasons()
        this.$buefy.toast.open({
          message: `表示順を変更しました`,
          type: "is-info",
        })
      })
      .catch(err => {
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else {
          this.$buefy.toast.open({
            message: `表示順変更に失敗しました`,
            type: "is-danger",
          })
        }
      })
    },
    async toggleVisibility (item) {
      const url = process.env.VUE_APP_BASE_URL + "delete_request_reasons/" + item.id + "/switch_visibility"
      await this.axios
        .put(url)
        .then(() => {
          this.getReasons()
          this.$buefy.toast.open({
            message: `表示／非表示を切り替えました`,
            type: "is-info",
          })
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `表示／非表示切替に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    getReasons () {
      const url = process.env.VUE_APP_BASE_URL + "delete_request_reasons"
      this.axios
        .get(url)
        .then(response => {
          this.reasons = response.data
          this.demoReasons = this.reasons.filter((reason) => { return reason.is_hidden == false })
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `選択肢の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    close () {
      this.dialog = false
      this.newReason = ''
      this.$refs.observer.reset()
    },
    async save () {
      this.isFormLoading = true
      const url = process.env.VUE_APP_BASE_URL + "delete_request_reasons"
      const data = {
        delete_request_reason: {
          reason: this.newReason
        }
      }
      const options = {
        url: url,
        method: 'POST',
        data: qs.stringify(data),
      }
      await this.axios(options)
      .then(() => {
        this.getReasons()
        this.$buefy.toast.open({
          message: `新規選択肢を登録しました`,
          type: "is-info"
        })
      })
      .catch(err => {
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else {
          this.$buefy.toast.open({
            message: `選択肢の登録に失敗しました`,
            type: "is-danger",
          })
        }
      })
      this.isFormLoading = false
      this.close()
    }
  },
  mounted() {
    this.loading = true
    this.getReasons()
    this.loading = false
  },
}
</script>

<style scoped>
</style>