import Vue from 'vue'

import {
  ValidationProvider,
  ValidationObserver,
  extend
} from 'vee-validate'

import {
  required,
  email,
  alpha_num,
  max,
  min
} from 'vee-validate/dist/rules'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// バリデーションルール
extend('required', {
  ...required,
  message: '{_field_}は入力必須です'
})
extend('email', {
  ...email,
  message: '{_field_}形式で入力してください'
})
extend('alpha_num', {
  ...alpha_num,
  message: '半角英数字で入力してください'
})
extend('max', {
  ...max,
  message: '{_field_}は{length}文字以下で入力してください'
})
extend('min', {
  ...min,
  message: '{_field_}は{length}文字以上で入力してください'
})
// パスワードを表示させた場合に全角文字に対するバリデーションが効かないため追加
extend('no_full_width', {
  validate(value) {
    // 全角文字がある場合はfalseを返す
    if (value.match(/[^\u0020-\u007E]/)) {
      return false
    } else {
      return true
    }
  },
  message: '半角英数字で入力してください'
})
extend('password_complex', {
  validate(value) {
    const hasNumber = /[0-9]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasUpperCase = /[A-Z]/.test(value);
    const isValidLength = value.length >= 8 && value.length <= 32
    const vaildCharacters = /^[0-9a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~]+$/.test(value); // 半角英数字のみ許可(記号やスペースを除外) -> 記号を許可するため、hasNoSymbolsの代わりに以下の条件を使用 2024/08/26 tamaki
    return hasNumber && (hasLowerCase || hasUpperCase) && isValidLength && vaildCharacters
  },
  message: '8文字以上32文字以下で、半角英字（小文字または大文字）、数字、および記号のいずれかを含む'
});

// カスタムルール
extend('password_confirmed', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: '新しいパスワードと一致しません'
})
extend('car_place', {
  validate(value) {
    if (value.match(/^[あ-ん]{3}$|^[一-鿐ヶ]{1,4}$/g)) {
      return true
    }
  },
  message: '[ヶ(小)]を含む漢字1～4文字、またはひらがな3文字で入力してください'
})
extend('car_class', {
  validate(value) {
    if (value.match(/^[0-9][0-9ACFHKLMPXY][0-9ACFHKLMPXY]$|^[0-9][0-9ACFHKLMPXY]$/g)) {
      return true
    }
  },
  message: '半角数字2～3文字で入力してください（3文字の場合は下1桁で[ACFHKLMPXY]のいずれかを使用できます'
})
extend('car_kana', {
  validate(value) {
    if (value.match(/^[あ-えか-さす-ふほ-をA-Z]$/g)) {
      return true
    }
  },
  message: 'お・し・へ・ん を除く、ひらがな1文字もしくは半角大文字アルファベット1文字で入力してください'
})
extend('car_number', {
  validate(value) {
    if (value.match(/^([1-9]\d{0,3})$/g)) {
      return true
    }
  },
  message: '1～9999の半角数字で入力してください。また先頭の桁が0の入力は許可されていません'
})
extend('natural_number', {
  validate(value) {
    if (value.toString().match(/^([1-9]\d*)$/g)) {
      return true
    }
  },
  message: '1以上の数字を半角で入力してください'
})
