<template>
  <v-container>
    <v-data-table
      dense
      :headers="headers"
      :items="request_client_stores"
      :search="search"
      :items-per-page="15"
      :loading="loading"
      loading-text="Loading... Please wait"
      disable-sort
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>店舗一覧</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="800px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                outlined
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                新規店舗追加
              </v-btn>
            </template>
            <v-card :loading="isFormLoading">
              <ValidationObserver ref="observer" v-slot="{ invalid }">
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form ref="form">
                      <v-row>
                        <v-col cols="12" :class="editedIndex === -1 ? 'pb-0' : ''">
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="店舗名"
                            rules="required"
                          >
                            <v-text-field
                              dense
                              outlined
                              v-model="editedItem.name"
                              label="店舗名"
                              :error-messages="errors"
                              :disabled="editedIndex !== -1"
                              @input="onChange"
                            >
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="editedIndex === -1" cols="12" class="pt-0">
                          <small>※ 新規に登録する店舗名は、既にcommonsに店舗情報が登録されていれば<span class="red--text">smapa_commons.servers.noteと一致</span>させてください。</small>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                          <v-text-field
                            dense
                            outlined
                            v-model="editedItem.address"
                            label="Address"
                            @input="onAdressChange"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col v-if="editedIndex === -1" cols="12" class="pt-0">
                          <small>※ 登録時点でAddress(下例参照)が決まっている場合は入力してください。</small><br>
                          <small class="ml-4">例) WebUIのURL: https://<span class="red--text yellow">smp2-999.sub.pitdesign.jp</span>/login_users/sign_in←の<span class="yellow pl-10"></span>部分</small><br>
                          <small>※ 後から設定することもできます。</small><br>
                          <small>※ Addressを設定する場合は、登録後の店舗一覧ページのリンクから該当店舗のWebUIへアクセスできることを確認してください。</small>
                        </v-col>
                        <v-col v-else cols="12" class="pt-0">
                          <small class="ml-4">例) WebUIのURL: https://<span class="red--text yellow">smp2-999.sub.pitdesign.jp</span>/login_users/sign_in←の<span class="yellow pl-10"></span>部分</small><br>
                          <small>※ Addressを更新する場合は、登録後の店舗一覧ページのリンクから該当店舗のWebUIへアクセスできることを確認してください。</small>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            dense
                            outlined
                            v-model="editedItem.port"
                            label="port"
                            hint="portは固定です"
                            persistent-hint
                            disabled
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            dense
                            outlined
                            v-model="editedItem.request_client_product_id"
                            label="製品"
                            :items="request_client_products"
                            item-text="name"
                            item-value="id"
                            hint="該当する製品を選択してください。"
                            persistent-hint
                            @input="onChange"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            dense
                            outlined
                            v-model="editedItem.is_deletion_right_holder"
                            label="削除権限"
                            :items="deletionHolderLabels"
                            item-text="label"
                            item-value="id"
                            hint="削除権限がピットデザイン側にある場合は'非権利者'、店舗側にある場合は'権利者'、不明な場合は'未設定'を選択してください"
                            persistent-hint
                            @input="onChange"
                          >
                          </v-select>
                        </v-col>
                        <v-col v-if="editedIndex > -1" cols="12" class="pb-0 mb-0">現在の精算不要車両登録数は {{ noPaymentRequiredVehiclesCount }} です</v-col>
                        <v-col cols="12">
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="精算不要車両登録上限"
                            rules="natural_number"
                          >
                            <v-text-field
                              dense
                              outlined
                              clearable
                              v-model="editedItem.white_limit"
                              label="精算不要車両登録上限"
                              hint="設定する場合は、登録できる精算不要車両の台数上限を1以上の数字で入力してください"
                              persistent-hint
                              @input="onChange"
                              :error-messages="errors"
                            >
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="editedItem.alert_notification_flag"
                            label="大型バスアラート機能の表示設定"
                            :items="[{ text: '表示', value: true }, { text: '非表示', value: false }]"
                            outlined
                            dense
                            @change="onChange"
                            hint="「表示」を選択した場合は、ホーム画面またはサイドメニューから大型バスアラート機能の新規登録をご利用できます"
                            persistent-hint
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="editedItem.accessible_vehicle_flag"
                            label="障害者一般車両の表示設定"
                            :items="[{ text: '表示', value: true }, { text: '非表示', value: false }]"
                            outlined
                            dense
                            @change="onChange"
                            hint="「表示」を選択した場合は、ホーム画面またはサイドメニューから障害者一般車両の新規登録をご利用できます"
                            persistent-hint
                          ></v-select>
                        </v-col>

                        <v-col cols="12" v-if="editedItem.accessible_vehicle_flag && editedItem.parkings.length > 0">
                          <v-card class="pa-2" outlined>
                            <label style="color: rgba(0, 0, 0, 0.6); font-size: 12px;">障害者一般車両の登録先</label>
                            <v-row>
                              
                              <v-col cols="12" md="4" sm="6" class="py-0">
                                <v-checkbox
                                  label="全選択/全解除"
                                  :value="allSelected"
                                  @change="toggleAll"
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col
                                v-for="parking in editedItem.parkings"
                                :key="parking.PARKING_ID"
                                cols="12"
                                md="4"
                                sm="6"
                                class="py-0"
                              >
                                <v-checkbox
                                  :label="parking.PARKING_NAME_1"
                                  :value="parking.PARKING_ID"
                                  v-model="editedItem.parking_ids"
                                  multiple
                                  @change="onParkingChange"
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                          </v-card>
                          <small class="persistent-hint ml-4" style="color: rgba(0, 0, 0, 0.6);">障害者一般車両の登録先を選択してください(複数選択可能)</small>
                        </v-col>

                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn small outlined color="blue-grey" @click="close" class="mb-6">キャンセル</v-btn>
                  <v-btn small outlined color="primary" @click="save" :disabled="!isChanged || invalid" class="mb-6">{{ submitLabel }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </ValidationObserver>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-container>
          <v-row class="px-4 red--text">
            <v-spacer></v-spacer>
            <small>※ 新規店舗追加前に既に同名の店舗が登録されていないか検索して確認してください。</small>
          </v-row>
        </v-container>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="キーワード検索"
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-container class="my-8">
          <v-row class="px-4 red--text">
            <small>※ address, portが未設定の店舗では精算不要車両のWebUIへの自動登録が失敗します。稼働前に「編集」ボタンから設定してください。</small>
          </v-row>
          <v-row class="mt-5 px-4 red--text">
            <small>※ address登録後はaddress列のリンクから該当店舗のWebUIへアクセスできることを確認してください。</small>
          </v-row>
        </v-container>
        <v-divider></v-divider>
      </template>

      <template #[`item.address`]="{ item }">
        <a v-if="item.address" target="_blank" :href="'https://' + item.address">
          {{ item.address }}
        </a>
        <v-chip v-else x-small color="orange">
          <span class="white--text font-weight-bold">未設定</span>
        </v-chip>
      </template>
      <template #[`item.is_deletion_right_holder`]="{ item }">
        <v-chip
          x-small
          :color="deletionHolderLabels.find(label => label.id == item.is_deletion_right_holder).color"
          class="white--text font-weight-bold"
        >
          {{ deletionHolderLabels.find(label => label.id == item.is_deletion_right_holder).label }}
        </v-chip>
      </template>
      <template #[`item.white_limit`]="{ item }">
        <span v-if="item.white_limit">
          {{ item.white_limit }}
        </span>
        <v-chip v-else x-small color="orange">
          <span class="white--text font-weight-bold">未設定</span>
        </v-chip>
      </template>
      <template #[`item.alert_notification_flag`]="{ item }">
        <span v-if="item.alert_notification_flag">表示</span>
        <span v-else>非表示</span>
      </template>
      <template #[`item.accessible_vehicle_flag`]="{ item }">
        <span v-if="item.accessible_vehicle_flag">表示</span>
        <span v-else>非表示</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn small outlined color="primary" class="mr-2" @click="editItem(item)">編集</v-btn>
      </template>
      <template #[`item.parking_names`]="{ item }">
        <span v-if="item.parking_ids && item.parking_ids.length > 0 && item.accessible_vehicle_flag">
          {{ item.parking_ids }}
        </span>
        <v-chip v-else x-small color="orange">
          <span class="white--text font-weight-bold">未設定</span>
        </v-chip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import qs from 'qs'

export default {
  data: () => ({
    isChanged: false,
    dialog: false,
    search: '',
    loading: false,
    isFormLoading: false,
    headers: [
      { text: 'ID', value: 'id', align: 'end' },
      { text: '店舗名', align: 'start', value: 'name' },
      { text: 'address(WebUI)', value: 'address' },
      { text: 'port', value: 'port' },
      { text: '削除権限', value: 'is_deletion_right_holder' },
      { text: '精算不要車両登録上限', value: 'white_limit', align: 'end' },
      { text: '大型バスアラート機能', value: 'alert_notification_flag', align: 'end' },
      { text: '障害者一般車両', value: 'accessible_vehicle_flag', align: 'end' },
      { text: '障害者一般車両の登録先id', value: 'parking_names', align: 'start' },
      { text: '操作', value: 'actions' },
      { text: '管理ユーザー', value: 'admin_user_count', align: 'end' },
      { text: '一般ユーザー', value: 'nomal_user_count', align: 'end' },
      { text: '請求取消依頼数', value: 'delete_request_history_count', align: 'end' },
    ],
    request_client_stores: [],
    deletionHolderLabels: [
      { id: 0, label: '未設定', color: 'orange' },
      { id: 1, label: '非権利者', color: 'cyan' },
      { id: 2, label: '権利者', color: 'green' },
    ],
    editedIndex: -1,
    editedItem: {
      name: '',
      address: '',
      port: '3306',
      is_deletion_right_holder: 0,
      white_limit: '',
      alert_notification_flag: false,
      accessible_vehicle_flag: false,
      parking_ids: [],
      parkings: [],
    },
    defaultItem: {
      name: '',
      address: '',
      port: '3306',
      is_deletion_right_holder: 0,
      white_limit: '',
      alert_notification_flag: false,
      accessible_vehicle_flag: false,
      parking_ids: [],
      parkings: [],
    },
    request_client_products: [],
    noPaymentRequiredVehiclesCount: "",
    allSelected: false,
  }),
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? '新規店舗追加' : '編集'
    },
    submitLabel () {
      return this.editedIndex === -1 ? '登録' : '更新'
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    toggleAll() {
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        // 全選択
        this.editedItem.parking_ids = this.editedItem.parkings.map(parking => parking.PARKING_ID);
      } else {
        // 全解除
        this.editedItem.parking_ids = [];
      }
      this.isChanged = true;
    },
    onParkingChange() {
      this.isChanged = true
      // 全てのチェックボックスを選択した場合に全選択/全解除にチェックを着ける
      this.allSelected = this.editedItem.parking_ids.length === this.editedItem.parkings.length
    },
    getParkingNames(parkingIds, parkings) {
      const parkingNames = parkingIds.map(parkingId => {
        const parking = parkings.find(p => p.PARKING_ID === parkingId)
        return parking ? parking.PARKING_NAME_1 : '未設定'
      })
      return parkingNames.join(', ')
    },
    onChange() {
      this.isChanged = true
    },
    onAdressChange() {
      if (this.editedIndex > -1) {
        this.isChanged = true
      }
    },
    editItem (item) {
      this.getNoPaymentRequiredVehiclesCount(item)
      this.editedIndex = this.request_client_stores.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.parkings = []
      const url = `${process.env.VUE_APP_BASE_URL}parkings/fetch_parking_name_1?host=${item.address}&port=${item.port}`
      this.axios
        .get(url)
        .then(response => {
          this.editedItem.parkings = response.data || []
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
      // this.editedItem.parkings = item.parkings || []
      this.dialog = true
    },
    getRequestClientProducts () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_products"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_products = response.data
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores/index_with_detail"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    getNoPaymentRequiredVehiclesCount (item) {
      const url = `${process.env.VUE_APP_BASE_URL}no_payment_required_vehicles/count?host=${item.address}&port=${item.port}`
      this.axios
        .get(url)
        .then(response => {
          this.noPaymentRequiredVehiclesCount = response.data
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    close () {
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      this.noPaymentRequiredVehiclesCount = ""
      this.$refs.observer.reset()
      this.isChanged = false
    },
    async save () {
      this.isFormLoading = true
      const product = this.request_client_products.find((item) => item.id === this.editedItem.request_client_product_id)
      const data = {
        request_client_store: {
          name: this.editedItem.name,
          address: this.editedItem.address,
          port: '3306',
          is_deletion_right_holder: this.editedItem.is_deletion_right_holder,
          white_limit: this.editedItem.white_limit,
          alert_notification_flag: this.editedItem.alert_notification_flag,
          accessible_vehicle_flag: this.editedItem.accessible_vehicle_flag,
          request_client_product_id: this.editedItem.request_client_product_id ? this.editedItem.request_client_product_id : null,
          product_sub_id: product ? product.sub_id : null,
          parking_ids: JSON.stringify(this.editedItem.parking_ids),
        }
      }
      if (this.editedIndex > -1) {
        const url = process.env.VUE_APP_BASE_URL + "request_client_stores/" + this.editedItem.id
        const options = {
          url: url,
          method: 'PUT',
          data: qs.stringify(data),
        }
        console.log(url)
        console.log(this.axios)
        await this.axios(options)
        .then(() => {
          this.getRequestClientStores()
          this.$buefy.toast.open({
            message: `店舗情報を更新しました`,
            type: "is-info",
          })
        })
        .catch(err => {
          console.log(err)
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報更新に失敗しました`,
              type: "is-danger",
            })
          }
        })
      } else {
        const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
        const options = {
          url: url,
          method: 'POST',
          data: qs.stringify(data),
        }
        await this.axios(options)
        .then(() => {
          this.$buefy.toast.open({
            message: `店舗を新規登録しました`,
            type: "is-info",
          })
          this.getRequestClientStores()
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else if (err.response && err.response.status == 409) {
            this.$buefy.toast.open({
              message: `既に使用されている店舗名です`,
              type: "is-danger",
            })
          } else {
            this.$buefy.toast.open({
              message: `新規店舗登録に失敗しました`,
              type: "is-danger",
            })
          }
        })
      }
      this.isFormLoading = false
      this.close()
    },
  },
  mounted() {
    this.loading = true
    this.getRequestClientStores()
    this.getRequestClientProducts()
    if (this.$route.params.request_client_store_name) {
      this.search = this.$route.params.request_client_store_name
    }
    this.loading = false
  },
}
</script>

<style scoped>
</style>
