<template>
  <v-card class="mx-auto" outlined>
    <div class="blur" :style="bgimage"></div>
    <v-card-title class="px-3 py-2">
      <small>
        {{ entrantTime }} 入庫
      </small>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined small class="px-0">
        <span @click="sendRecord">
          選択
        </span>
      </v-btn>
    </v-card-title>
  </v-card>
</template>

<script>
import moment from "moment"

export default {
  props: ["record"],
  data: () => ({
  }),
  computed: {
    bgimage () {
      return `background: center / cover no-repeat url(${this.record.IMAGE_DATA_OBJECT_PATH});`
    },
    entrantTime () {
      return moment(this.record.ENTRANT_TIME).format( "YY/MM/DD HH:mm:ss" )
    },
  },
  methods: {
    sendRecord () {
      this.$emit('receiveRecord', this.record)
    }
  },
}
</script>

<style scoped>
.blur {
  position: relative;
  max-width: 100%;
  padding-bottom: 75%;
  background-size: cover;
  overflow: hidden;
}
.blur:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	background: inherit;
	background-clip: content-box;
	width: 100%;
	height: 100%;
	padding-bottom: 60%;
	filter: blur(5px);
}
</style>