<template>
  <v-container>
    <BrowserVersionAlert/>
    <ReleaseNote/>
    <v-card class="my-2">
      <v-card-title class="text-h6">依頼種別を選択</v-card-title>
      <v-list nav dense> 
        <v-list-item v-if="$store.getters.isAdminUser || $store.getters.isAeonYonoAdmin" link to="/dashboard">
          <v-list-item-title class="text-caption">ダッシュボード<span v-if="$store.getters.isAdminUser">(イオンモール与野)</span></v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$store.getters.isAdminUser || $store.getters.isSendaiAdmin" link to="/dashboard_with_reservations">
          <v-list-item-title class="text-caption">ダッシュボード<span v-if="$store.getters.isAdminUser">(仙台空港)</span></v-list-item-title>
        </v-list-item>
        <v-list-group :value="true" no-action sub-group v-if="$store.getters.isAdminUser || $store.getters.isNomalUser || $store.getters.isSendaiAdmin || $store.getters.isSendaiInformation || $store.getters.isAeonMakuhariAdmin || $store.getters.isAeonYonoAdmin">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>駐車料金請求取消依頼</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link dense to="/new-delete-request">
            <v-list-item-title>申請</v-list-item-title>
          </v-list-item>
          <v-list-item link dense to="/delete-request-histories">
            <v-list-item-title>申請状況一覧</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="true" no-action sub-group v-if="$store.getters.isAdminUser || $store.getters.isNomalUser || $store.getters.isSendaiAdmin || $store.getters.isSendaiInformation || $store.getters.isAeonMakuhariAdmin || $store.getters.isAeonYonoAdmin">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>精算不要車両</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/new-no-payment-required-vehicle">
            <v-list-item-title>登録</v-list-item-title>
          </v-list-item>
          <v-list-item v-else disabled link dense to="/new-no-payment-required-vehicle">
            <v-list-item-title>登録（準備中）</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/no-payment-required-vehicles">
            <v-list-item-title>登録済車両一覧</v-list-item-title>
          </v-list-item>
          <v-list-item v-else disabled link dense to="/no-payment-required-vehicles">
            <v-list-item-title>登録済車両一覧（準備中）</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/output-no-payment-required-vehicle">
            <v-list-item-title>CSV出力</v-list-item-title>
          </v-list-item>
          <v-list-item v-else disabled link dense to="/output-no-payment-required-vehicles">
            <v-list-item-title>CSV出力（準備中）</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="true" no-action sub-group class="pb-5" v-if="$store.getters.isAdminUser || $store.getters.isNomalUser || $store.getters.isSendaiAdmin || $store.getters.isSendaiInformation || $store.getters.isAeonMakuhariAdmin || $store.getters.isAeonYonoAdmin">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>アラート通知車両</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/new-alert-vehicles">
            <v-list-item-title>登録</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/alert-vehicles">
            <v-list-item-title>登録済車両一覧</v-list-item-title>
          </v-list-item>
          <v-list-item v-else link dense disabled to="/alert-vehicles">
            <v-list-item-title>登録済車両一覧(準備中)
            </v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="true" no-action sub-group class="pb-5" v-if="$store.getters.isAdminUser || (($store.getters.isNomalUser || $store.getters.isAeonMakuhariAdmin || $store.getters.isSendaiAdmin || $store.getters.isSendaiInformation || $store.getters.isAeonMakuhariAccessible) && $store.getters.isAlertNotificationFlagged)">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>入場許可車両</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/new-gate-control-vehicles">
            <v-list-item-title>登録</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/gate-control-vehicles">
            <v-list-item-title>登録済車両一覧</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="true" no-action sub-group class="pb-5" v-if="$store.getters.isAdminUser || $store.getters.isAeonMakuhariUser">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>フロア・エリア表示</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/parking_maps">
            <v-list-item-title>フロア・エリア検索</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="true" no-action sub-group class="pb-5" v-if="$store.getters.isAdminUser || $store.getters.isAeonMakuhariUser || $store.getters.isAeonYonoAdmin">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>入出場履歴<span v-if="$store.getters.isAdminUser">(幕張新都心,イオンモール与野)</span></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/vehicle-informations">
            <v-list-item-title>入出場照会</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="true" no-action sub-group class="pb-5" v-if="$store.getters.isAdminUser || (($store.getters.isNomalUser || $store.getters.isSendaiAdmin || $store.getters.isSendaiInformation || $store.getters.isAeonMakuhariAdmin || $store.getters.isAeonMakuhariAccessible || $store.getters.isAeonMakuhariUser) && $store.getters.isAlertNotificationFlagged)">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>大型バスアラート機能</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isSetStoreAddress" link dense to="/new-alert-notifications">
            <v-list-item-title>通知先登録</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.getters.isSetStoreAddress" link dense to="/alert-notifications">
            <v-list-item-title>大型バスアラート通知先一覧</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="true" no-action sub-group class="pb-5" v-if="$store.getters.isAdminUser || $store.getters.canManageUnpaidAlert">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>駐車料金未払い車両アラート機能</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isSetStoreAddress" link dense to="/unpaid-alert-notifications">
            <v-list-item-title>一覧</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="true" no-action sub-group class="pb-5" v-if="$store.getters.isAdminUser || $store.getters.canManageUnpaidAlert">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>長期駐車車両アラート機能</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isSetStoreAddress" link dense to="/long-term-parking-alert-notifications">
            <v-list-item-title>一覧</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          :value="true"
          no-action
          sub-group
          class="pb-5"
          v-if="$store.getters.isAdminUser || $store.getters.canManageAccessible"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>障害者一般車両</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isSetStoreAddress" link dense to="/new-accessible-vehicles">
            <v-list-item-title>登録</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.getters.isSetStoreAddress" link dense to="/accessible-vehicles">
            <v-list-item-title>登録済車両一覧</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
          :value="true"
          no-action
          sub-group
          class="pb-5"
          v-if="$store.getters.isAdminUser || $store.getters.canManageReservation"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>予約情報</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isSetStoreAddress" link dense to="/reservations">
            <v-list-item-title>予約情報一覧</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="true" v-if="$store.getters.isAdminUser || $store.getters.canManageVehicle" no-action sub-group class="pb-5">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>履歴照会</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link dense to="/sendai-airport-vehicle-informations">
            <v-list-item-title>入出庫照会</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/sales-inquiries">
            <v-list-item-title>売上照会</v-list-item-title>
          </v-list-item>
        </v-list-group>

      </v-list>
    </v-card>
    <!-- <v-card class="my-2" v-if="isStagingOrDevelopment">
      <v-card-title>
        <div class="text-h6">問い合わせ</div>
      </v-card-title>
      <v-container>
        <v-row>
          <v-col v-for="(item, i) in items" :key="i" cols="12">
            <div class="align-center">
              <span>{{ item.text }}</span>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs}">
                  <v-btn icon color="grey" dark v-bind="attrs" v-on="on" class="pb-1">
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <div v-for="(bullet, index) in formatSubText(item.subText)" :key="index">
                  <div>{{ bullet }}</div>
                </div>
              </v-tooltip>
            </div>
            <div><v-icon left small>mdi-email-outline</v-icon><a :href="generateMailToLink(item.email)">{{ item.email }}</a></div>
          </v-col>
          <v-col cols="12">
            <div>■ お客様トラブルやその他お問い合わせはコールセンターまで
              <br/>
              <v-icon left small>mdi-phone</v-icon><span>0570-011-500 [24時間365日 対応]</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card> -->
    <!-- <BrowserVersionAlert/> -->
  </v-container>
</template>

<script>
import BrowserVersionAlert from '../../components/BrowserVersionAlert.vue'
import ReleaseNote from '../../components/ReleaseNote.vue'

export default {
  name: 'Home',
  components: { BrowserVersionAlert, ReleaseNote },
  data: () => ({
    expand: false,
    items: [
      { text: '■ 運用変更・月極管理に関するお問い合わせ', email: 'eigyo_solutiont@pitdesign.jp', subText: '・料金変更、運用変更について<br>・看板・告知を追加してほしい<br>・月極関連の問い合わせ など' },
      { text: '■ 車両の登録・取り締まりに関するお問い合わせ', email: 'pit_field@pitdesign.jp', subText: '・アラート登録、解除について<br>・精算不要車両の登録、解除について<br>・カーシェア、レンタカー会社からの未精算について など' },
      { text: '■ 精算機やモバイルアプリのトラブル、機器に関するお問い合わせ', email: 'maintenanceg@pitdesign.jp', subText: '・機器が壊れている<br>システムトラブル、アプリ不具合<br>・事故対応関連 など' },
      { text: '■ スマパのご質問に関するお問い合わせ', email: 'pit_service@pitdesign.jp', subText: '・お客様からの各種問い合わせ<br>・スマートパークの利用方法に関する質問<br>・領収書、返金の対応 など' }
    ]
  }),
  computed: {
    generateMailToLink () {
      return (val) => {
        return `mailto:${val}`
      }
    },
    formatSubText () {
      return (val) => {
        return val.split('<br>').map(text => text.trim()).filter(Boolean)
      }
    },
    isAccessibleAccount () {
      return this.$store.getters.isAdminUser || (this.$store.getters.isSendaiUser && this.$store.getters.isAccessibleVehicleFlagged) ||
        (this.$store.getters.isRakeTownAdmin && this.$store.getters.isAccessibleVehicleFlagged) ||
        ((this.$store.getters.isAeonMakuhariAccessible || this.$store.getters.isAeonMakuhariUser) && this.$store.getters.isAccessibleVehicleFlagged) ||
        (this.$store.getters.isAeonUrawamisonoAccessible && this.$store.getters.isAccessibleVehicleFlagged) ||
        (this.$store.getters.isAeonRinkuizumiminamiAccessible && this.$store.getters.isAccessibleVehicleFlagged)
    },
    isStagingOrDevelopment () {
      return process.env.VUE_APP_BASE_URL != "https://commons-api.sub.pitdesign.jp/api/request_app_client/"
    }
  },
  methods: {
    fetchUserStatus () {
      this.$store.dispatch("fetchUserStatus")
    }
  },
  mounted() {
    this.fetchUserStatus()
  },
}
</script>

<style scoped>
</style>
