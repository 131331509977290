import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import './plugins/veevalidate'

Vue.config.productionTip = false

axios.defaults.withCredentials = true
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded"
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Authorization'] = process.env.VUE_APP_COMMON_ACCESS_KEY
Vue.use(VueAxios, axios)

Vue.use(Buefy)

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')
