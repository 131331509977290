<template>
  <v-container>
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>
          請求取消 依頼内容入力
          <v-chip
            small
            v-if="reapplyFlg"
            class="ml-2 mr-2 font-weight-bold"
            color="red"
            text-color="white"
          >
            再依頼
          </v-chip>
        </v-toolbar-title>
      </v-toolbar>
      <ValidationObserver v-slot="{ invalid }">
        <v-container>
          <v-row>
            <v-col cols="12"  class="pt-0 mb-4">
              <v-alert dense text type="warning" :icon="false" class="mb-0">
                <small>
                  ※御社との取り組みに基づき、請求取消できない場合があります。<br>
                  ※取引業者等の車両であっても駐車料金をいただく場合があります。
                </small>
              </v-alert>
            </v-col>
            <v-col cols="12" lg="6" class="py-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="店舗名"
                rules="required"
              >
                <v-autocomplete
                  :outlined="$store.getters.isAdminUser"
                  dense
                  label="店舗名"
                  :items="request_client_stores"
                  item-text="name"
                  item-value="id"
                  v-model="selectedRequestClientStore"
                  return-object
                  :disabled="!$store.getters.isAdminUser"
                  :error-messages="errors"
                ></v-autocomplete>
              </ValidationProvider>
            </v-col>
            <v-col v-if="(selectedRequestClientStore && Object.keys(selectedRequestClientStore).length) || selectedTargetKind !== ''" cols="12" lg="6" class="py-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="依頼形式"
                rules="required"
              >
                <v-select
                  outlined
                  dense
                  v-model="selectedTargetKind"
                  :items="targetKindItems"
                  item-text="label"
                  item-value="id"
                  label="依頼形式"
                  :error-messages="errors"
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
          <template v-if="selectedTargetKind === 0">
            <v-row>
              <v-col cols="12" class="py-0">
                <small>
                  請求取消対象日と4桁ナンバーを入力してください<span class="red--text">(必須)</span>
                </small>
                <v-divider class="mt-0 mb-3"></v-divider>
                <v-menu
                  v-model="showTargetDateSelector"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="請求取消対象日"
                      rules="required"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="selectedTargetDateFormatted"
                        label="請求取消対象日"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="selectedTargetDate"
                    :max ="today"
                    no-title
                    locale="ja"
                    @input="showTargetDateSelector = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </template>
          <template v-if="selectedTargetKind === 1">
            <v-row>
              <v-col cols="12" class="py-0">
                <small>
                  開始日、終了日を入力してください<span class="red--text">(必須)</span>
                </small>
                <v-divider class="mt-0 mb-3"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-0">
                <v-menu
                  v-model="showTargetStartDateSelector"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="開始日"
                      rules="required"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="selectedTargetStartDateFormatted"
                        label="開始日"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="selectedTargetStartDate"
                    :max="yesterday"
                    no-title
                    locale="ja"
                    @input="showTargetStartDateSelector = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pl-0 py-0">
                <v-menu
                  v-model="showTargetEndDateSelector"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="終了日"
                      rules="required"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="selectedTargetEndDateFormatted"
                        label="終了日"
                        prepend-icon="mdi-arrow-right"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="selectedTargetEndDate"
                    :max="today"
                    :min="selectedTargetStartDate"
                    no-title
                    locale="ja"
                    @input="showTargetEndDateSelector = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </template>
          <template v-if="selectedTargetKind !== ''">
            <v-row v-if="selectedTargetKind === 1 || selectedTargetKind === 2">
              <v-col cols="12" class="py-0">
                <small>
                  以下全ての項目を入力してください<span class="red--text">(必須)</span>
                </small>
                <v-divider class="mt-0 mb-3"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="地域"
                  :rules="(selectedTargetKind === 1 || selectedTargetKind === 2) ? 'required|car_place' : 'car_place'"
                >
                  <v-text-field
                    outlined
                    dense
                    label="地域 (例: 旭川)"
                    v-model="carPlace"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="クラス"
                  :rules="(selectedTargetKind === 1 || selectedTargetKind === 2) ? 'required|car_class' : 'car_class'"
                >
                  <v-text-field
                    outlined
                    dense
                    label="クラス (例: 500)"
                    v-model="carClass"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="かな"
                  :rules="(selectedTargetKind === 1 || selectedTargetKind === 2) ? 'required|car_kana' : 'car_kana'"
                >
                  <v-text-field
                    outlined
                    dense
                    label="かな (例: あ)"
                    v-model="carKana"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="4桁ナンバー"
                  rules="required|car_number"
                >
                  <v-text-field
                    outlined
                    dense
                    label="4桁ナンバー (例: 3, 88, 456)"
                    v-model="carNumber"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row v-if="reapplyFlg === 0 && selectedRequestClientStore && selectedRequestClientStore.address" class="mb-5">
              <v-spacer></v-spacer>
              <v-btn
                outlined
                small
                color="teal"
                @click.stop="inOutRecordsDialog = true"
              >
                利用履歴から車両を再検索
              </v-btn>
              <v-spacer></v-spacer>
            </v-row>
            <template v-if="selectedTargetKind === 0">
              <v-row>
                <v-col cols="12" lg="6" class="py-0">
                  <small>
                    精算機以外で駐車料金の受取があった場合は金額を入力してください
                  </small>
                  <v-divider class="mt-0 mb-3"></v-divider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="受領金額"
                    rules="natural_number"
                  >
                    <v-text-field
                      outlined
                      dense
                      label="受領金額 (例: 1000)"
                      v-model="receipts"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" lg="6" class="py-0">
                  <small>
                    車種や色など車両の特徴がわかる場合は入力してください
                  </small>
                  <v-divider class="mt-0 mb-3"></v-divider>
                  <v-text-field
                    outlined
                    dense
                    label="車種、色など (例: 黒のミニバン)"
                    v-model="note"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col cols="12" class="py-0">
                <template v-if="selectedTargetKind === 0">
                  <small>
                    依頼理由を選択してください<span class="red--text">(必須)</span>
                  </small>
                  <v-divider class="mt-0 mb-3"></v-divider>
                </template>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="依頼理由"
                  rules="required"
                >
                  <v-select
                    outlined
                    dense
                    v-model="requestReasonId"
                    :items="requestReasonItems"
                    item-text="reason"
                    item-value="id"
                    label="依頼理由"
                    :error-messages="errors"
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col v-if="requestReasonId == 4 || requestReasonId == 5" cols="12" class="py-0">
                <template v-if="selectedTargetKind === 0 && requestReasonId === 5">
                  <small>
                    詳細を入力してください<span class="red--text">(必須)</span>
                  </small>
                  <v-divider class="mt-0 mb-3"></v-divider>
                </template>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="理由:詳細"
                  :rules="requestReasonId === 5 ? 'required' : null"
                >
                  <v-textarea
                    outlined
                    dense
                    v-model="requestReasonText"
                    no-resize
                    rows="2"
                    label="理由:詳細"
                    :error-messages="errors"
                  ></v-textarea>
                </ValidationProvider>
              </v-col>
            </v-row>
          </template>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="brue-gray" class="mr-2" to="/">戻る</v-btn>
          <v-btn small outlined color="primary" :disabled="invalid" @click.stop="dialog = true">依頼内容確認</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </ValidationObserver>
      <v-dialog
        v-model="dialog"
        max-width="500px"
        scrollable
      >
        <v-card>
          <v-card-title>
            依頼内容確認
            <v-chip
              small
              v-if="reapplyFlg"
              class="ml-2 mr-2 font-weight-bold"
              color="red"
              text-color="white"
            >
              再依頼
            </v-chip>
          </v-card-title>
          <v-divider class="my-0"></v-divider>
          <v-card-text>
            <v-row>
              <small class="my-5">以下の内容で依頼します。よろしいですか？</small>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="店舗名"
                  :value="selectedRequestClientStore ? selectedRequestClientStore.name : ''"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="依頼形式"
                  v-model="selectedTargetKindLabel"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col v-if="selectedTargetKind === 0" cols="12" class="py-0">
                <v-text-field
                  v-model="selectedTargetDateFormatted"
                  label="請求取消対象日"
                  prepend-icon="mdi-calendar"
                  readonly
                ></v-text-field>
              </v-col>
              <template v-if="selectedTargetKind === 1">
                <v-col cols="6" class="py-0">
                  <v-text-field
                    v-model="selectedTargetStartDateFormatted"
                    label="開始日"
                    prepend-icon="mdi-calendar"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pl-0 py-0">
                  <v-text-field
                    v-model="selectedTargetEndDateFormatted"
                    label="終了日"
                    prepend-icon="mdi-arrow-right"
                    readonly
                  ></v-text-field>
                </v-col>
              </template>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="ナンバープレート情報"
                  v-model="numberInfo"
                  readonly
                ></v-text-field>
              </v-col>
              <template v-if="selectedTargetKind === 0">
                <v-col cols="12" lg="6" class="py-0">
                  <v-text-field
                    label="受領金額 (例: 1000)"
                    v-model="receipts"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="py-0">
                  <v-text-field
                    label="車種、色など (例: 黒のミニバン)"
                    v-model="note"
                    readonly
                  ></v-text-field>
                </v-col>
              </template>
              <v-col cols="12" class="py-0">
                <v-select
                  v-model="requestReasonId"
                  :items="requestReasonItems"
                  item-text="reason"
                  item-value="id"
                  label="依頼理由"
                  readonly
                ></v-select>
              </v-col>
              <v-col v-if="requestReasonId == 4 || requestReasonId == 5" cols="12" class="py-0">
                <v-textarea
                  v-model="requestReasonText"
                  no-resize
                  rows="2"
                  label="理由:詳細"
                  readonly
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ma-0"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small outlined color="blue-grey" @click="dialog = false">修正する</v-btn>
            <v-btn small outlined color="primary" :loading="isLoading" :disabled="isLoading" @click="save">依頼する</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <SearchInOutRecords
        :selectedRequestClientStore="selectedRequestClientStore"
        :inOutRecordsDialog="inOutRecordsDialog"
        searchFrom="deleteRequestHistories"
        @applyRecord="applyRecord"
        @applyParkedRecords="parkedRecords = $event"
        @closeDialog="closeDialog"
      />
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment"
import qs from 'qs'
import SearchInOutRecords from '../../components/SearchInOutRecords'

export default {
  components: {
    SearchInOutRecords,
  },
  data: () => ({
    isLoading: false,
    request_client_stores: [],
    selectedRequestClientStore: null,

    targetKindItems: [
      { id: '', label: '-- 選択してください --' },
      { id: 0, label: '１日分' },
      { id: 1, label: '期間指定' },
      { id: 2, label: '全期間' },
    ],
    selectedTargetKind: '',

    showTargetDateSelector: false,
    showTargetStartDateSelector: false,
    showTargetEndDateSelector: false,

    selectedTargetDate: '',
    selectedTargetDateFormatted: '',
    selectedTargetStartDate: '',
    selectedTargetStartDateFormatted: '',
    selectedTargetEndDate: '',
    selectedTargetEndDateFormatted: '',
    carPlace: '',
    carClass: '',
    carKana: '',
    carNumber: '',
    receipts: '',
    note: '',
    requestReasonItems: [],
    requestReasonId: '',
    requestReasonText: '',
    reapplyFlg: 0,
    originalId: '',

    dialog: false,
    inOutRecordsDialog: false,

    parkedRecords: null
  }),
  computed: {
    today () {
      return moment().format('yyyy-MM-DD')
    },
    yesterday () {
      return moment().subtract(1, 'days').format('yyyy-MM-DD')
    },
    selectedTargetKindLabel () {
      return this.targetKindItems.find(item => item.id === this.selectedTargetKind).label
    },
    numberInfo () {
      let info = ''
      if (this.carPlace) { info += this.carPlace + ' ' }
      if (this.carClass) { info += this.carClass + ' ' }
      if (this.carKana) { info += this.carKana + ' ' }
      info += this.carNumber
      return info
    },
  },
  watch: {
    selectedRequestClientStore (value) {
      if (value && value.address && this.selectedTargetKind !== "" && this.reapplyFlg == 0) {
        this.inOutRecordsDialog = true
      }
    },
    selectedTargetKind (value) {
      if (value !== "" && this.selectedRequestClientStore && this.selectedRequestClientStore.address && this.carNumber == "") {
        this.inOutRecordsDialog = true
      }
    },
    selectedTargetDate (value) {
      if (value) { this.selectedTargetDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetStartDate (value) {
      if (value) { this.selectedTargetStartDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetEndDate (value) {
      if (value) { this.selectedTargetEndDateFormatted = moment(value).format('YY/MM/DD') }
    },
  },
  methods: {
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
            if (this.$route.params.id) {
              this.setOriginalRequest()
            } else {
              this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
            }
          }
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    getReasons () {
      const url = process.env.VUE_APP_BASE_URL + "delete_request_reasons"
      this.axios
        .get(url)
        .then(response => {
          this.requestReasonItems = response.data.filter((reason) => { return reason.is_hidden == false })
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `選択肢の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    async save () {
      this.isLoading = true
      let url = process.env.VUE_APP_BASE_URL
      const carNumber = ('****' + this.carNumber).slice(-4)
      const parked = this.parkedRecords.some((record) => record.PLACE === this.carPlace && record.CLASS_NUMBER === this.carClass && record.KANA === this.carKana && record.CAR_NUMBER === carNumber)
      // webuiのaddressがあり、請求取消権限が店舗側にあり、入庫中ではなく、車両情報４種類が入力されている場合
      if (this.$store.getters.isSetStoreAddress && this.$store.getters.isDeletionRightHolder && this.carPlace && this.carClass && this.carKana && this.carNumber && !parked) {
        // WebUIの自動請求取消apiを叩くurl
        url += "unpaid_informations/destroy_filtered"
      } else {
        // 請求取消依頼を登録するurl
        url += "delete_request_histories"
      }
      let data = {
        delete_request_history: {
          status: 0,
          target_kind: this.selectedTargetKind,
          request_client_user_id: this.$store.getters.requestClientUserId,
          request_client_store_id: this.selectedRequestClientStore.id,
          car_number: this.carNumber,
          car_place: this.carPlace,
          car_class: this.carClass,
          car_kana: this.carKana,
          delete_request_reason_id: this.requestReasonId,
          is_reapply: this.reapplyFlg,
          original_id: this.originalId,
        },
      }
      if (this.requestReasonText) {
        data.delete_request_history.request_reason_text = this.requestReasonText
      }
      if (this.selectedTargetKind === 0) {
        data.delete_request_history.entrant_time = this.selectedTargetDate
        if (this.note) { data.delete_request_history.note = this.note }
        if (this.receipts) { data.delete_request_history.receipts = this.receipts }
      } else if (this.selectedTargetKind === 1) {
        data.delete_request_period = {
          started_at: this.selectedTargetStartDate,
          ended_at: this.selectedTargetEndDate,
        }
      }
      const options = {
        url: url,
        method: 'POST',
        data: qs.stringify(data),
      }
      await this.axios(options)
      .then(() => {
        this.$router.push({path: "/delete-request-histories"})
        this.$buefy.toast.open({
          message: `請求取消を依頼しました`,
          type: "is-info"
        })
      })
      .catch(err => {
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else {
          this.$buefy.toast.open({
            message: `請求取消の依頼に失敗しました`,
            type: "is-danger",
          })
        }
      })
      this.isLoading = false
      this.dialog = false
    },
    setOriginalRequest () {
      const url = process.env.VUE_APP_BASE_URL + "delete_request_histories/" + this.$route.params.id
      this.axios
        .get(url)
        .then(response => {
          const originalRequest = response.data
          this.reapplyFlg = 1
          this.originalId = originalRequest.id
          this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == originalRequest.request_client_store_id)
          this.selectedTargetKind = originalRequest.target_kind
          this.carNumber = originalRequest.car_number
          this.carPlace = originalRequest.car_place
          this.carClass = originalRequest.car_class
          this.carKana = originalRequest.car_kana
          this.requestReasonId = originalRequest.delete_request_reason_id
          this.requestReasonText = originalRequest.request_reason_text || ''
          if (originalRequest.target_kind === 0) { this.selectedTargetDate = originalRequest.entrant_time }
          this.note = originalRequest.note || ''
          this.receipts = originalRequest.receipts || ''
          if (originalRequest.target_kind === 1) {
            this.selectedTargetStartDate = originalRequest.started_at
            this.selectedTargetEndDate = originalRequest.ended_at
          }
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    applyRecord (record) {
      this.carPlace = record.PLACE
      this.carClass = record.CLASS_NUMBER
      this.carKana = record.KANA
      this.carNumber = record.CAR_NUMBER.replace(/\*/g,'')
      this.selectedTargetDate = moment(record.ENTRANT_DTE).format('yyyy-MM-DD')
      this.selectedTargetStartDate = moment(record.ENTRANT_DTE).subtract(1, 'days').format('yyyy-MM-DD')
      this.selectedTargetEndDate = moment(record.ENTRANT_DTE).format('yyyy-MM-DD')
      this.inOutRecordsDialog = false
    },
    closeDialog () {
      this.inOutRecordsDialog = false
    },
    fetchUserStatus () {
      this.$store.dispatch("fetchUserStatus")
    }
  },
  mounted() {
    this.fetchUserStatus()
    this.getRequestClientStores()
    this.getReasons()
    this.selectedTargetDate = moment().format('yyyy-MM-DD')
    this.selectedTargetStartDate = moment().subtract(1, 'days').format('yyyy-MM-DD')
    this.selectedTargetEndDate = moment().format('yyyy-MM-DD')
  },
}
</script>

<style scoped>
</style>
