<template>
  <v-container>
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>
          精算不要車両 新規登録
        </v-toolbar-title>
      </v-toolbar>
      <ValidationObserver v-slot="{ invalid }">
        <v-container>
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-alert dense text type="info" :icon="false" class="mb-2">
                <small>
                  スマートパークは、従業員様や納品業者様など店舗・施設様のご判断で駐車場を無料で利用いただく車両については、精算機にて操作をせずに出庫できる機能を備えております。<br>
                  この機能を利用するには不正出庫車両と区別するため、 「精算不要車両」としての事前登録が必要となります。
                </small>
              </v-alert>
            </v-col>
            <v-col v-if="!isRegisterableNewWhite" cols="12" class="py-0 mt-0 mb-4">
              <v-alert dense text type="error" class="mb-0">
                <small>
                  精算不要車両登録上限に達しています。新規登録する場合は既存の登録を削除してください。
                </small>
                <v-btn
                  class="mx-3"
                  color="error"
                  elevation="0"
                  small
                  to="/no-payment-required-vehicles"
                >削除はこちらから</v-btn>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="6" class="py-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="店舗名"
                rules="required"
              >
                <v-autocomplete
                  :outlined="$store.getters.isAdminUser"
                  dense
                  label="店舗名"
                  :items="request_client_stores"
                  item-text="name"
                  item-value="id"
                  v-model="selectedRequestClientStore"
                  return-object
                  :disabled="!$store.getters.isAdminUser"
                  :error-messages="errors"
                ></v-autocomplete>
              </ValidationProvider>
            </v-col>
            <v-col v-if="selectedRequestClientStore && parkings.length > 2" cols="12" lg="6" class="py-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="対象駐車場"
                rules="required"
              >
                <v-select
                  outlined
                  dense
                  v-model="selectedParking"
                  return-object
                  :items="parkings"
                  item-text="PARKING_NAME"
                  item-value="PARKING_ID"
                  label="対象駐車場"
                  :error-messages="errors"
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
          <template v-if="selectedRequestClientStore">
            <v-row>
              <v-col cols="12" class="py-0">
                <small>
                  精算不要車両情報を入力してください<span class="red--text">(必須)</span>
                </small>
                <v-divider class="mt-0 mb-3"></v-divider>
              </v-col>
              <v-col cols="12" lg="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="所属"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    dense
                    label="所属(例: 〇〇店)"
                    v-model="organization"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="ご利用者名"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    dense
                    label="ご利用者名 (例: 〇〇太郎)"
                    v-model="ownerName"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="地域"
                  rules="required|car_place"
                >
                  <v-text-field
                    outlined
                    dense
                    label="地域 (例: 旭川)"
                    v-model="carPlace"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="クラス"
                  rules="required|car_class"
                >
                  <v-text-field
                    outlined
                    dense
                    label="クラス (例: 500)"
                    v-model="carClass"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="かな"
                  rules="required|car_kana"
                >
                  <v-text-field
                    outlined
                    dense
                    label="かな (例: あ)"
                    v-model="carKana"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="3" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="4桁ナンバー"
                  rules="required|car_number"
                >
                  <v-text-field
                    outlined
                    dense
                    label="4桁ナンバー (例: 3, 88, 456)"
                    v-model="carNumber"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row v-if="selectedRequestClientStore && selectedRequestClientStore.address" class="mb-5">
              <v-spacer></v-spacer>
              <v-btn
                outlined
                small
                color="teal"
                @click.stop="inOutRecordsDialog = true"
              >
                利用履歴から車両を再検索
              </v-btn>
              <v-spacer></v-spacer>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <small>
                  有効期間の開始日・終了日を選択してください<span class="red--text">(開始日は必須)</span>
                </small>
                <v-divider class="mt-0 mb-3"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-0">
                <v-menu
                  v-model="showTargetStartDateSelector"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="開始日"
                      rules="required"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="selectedTargetStartDateFormatted"
                        label="開始日"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="selectedTargetStartDate"
                    :max="maxStartDate ? maxStartDate : ''"
                    no-title
                    locale="ja"
                    @input="showTargetStartDateSelector = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pl-0 py-0">
                <v-menu
                  v-model="showTargetEndDateSelector"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      clearable
                      outlined
                      dense
                      v-model="selectedTargetEndDateFormatted"
                      label="終了日"
                      prepend-icon="mdi-arrow-right"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectedTargetEndDate"
                    :min="minEndDate ? minEndDate : ''"
                    no-title
                    locale="ja"
                    @input="showTargetEndDateSelector = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="備考"
                  :rules="`max:${noteLimit}`"
                >
                  <v-textarea
                    outlined
                    dense
                    v-model="note"
                    no-resize
                    rows="4"
                    label="備考"
                    :counter="noteLimit"
                    :error-messages="errors"
                  ></v-textarea>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-card flat outlined class="pa-2">
                  <small>
                    <span class="font-weight-bold">【申請前同意事項】</span><br>
                    ① ナンバー登録の申請は、駐車場をご利用になる前に登録をお願い致します。登録がない場合や遅れた場合、番号誤入力等の場合は未払い車両扱いとなり、未払い駐車料金の請求や車両のタイヤロック等の取締り対応を行う場合があります。<br>
                    ② また、取締り業務の一環で当社が対象車両の登録事項証明書等の個人情報を取得する場合があります。<br>
                    ③ 精算不要車両に登録漏れがありますと「未払い出庫率」が実際よりも高くなるため、正しい運営状態の把握ができなくなります。
                  </small>
                </v-card>
              </v-col>
              <v-col cols="12" class="py-0 d-flex justify-center">
                <v-checkbox v-model="agree">
                  <template v-slot:label>
                    <span class="black--text">上記<span class="font-weight-bold">「申請前同意事項」</span>に同意し、精算不要車両の登録を貴社に依頼します。</span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </template>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="brue-gray" class="mr-2" to="/">戻る</v-btn>
          <v-btn small outlined color="primary" :disabled="invalid || !isRegisterableNewWhite || !agree" @click.stop="dialog = true">登録内容確認</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </ValidationObserver>
      <v-dialog
        v-model="dialog"
        max-width="500px"
        scrollable
      >
        <v-card>
          <v-card-title>
            登録内容確認
          </v-card-title>
          <v-divider class="my-0"></v-divider>
          <v-card-text>
            <v-row>
              <small class="my-5">以下の内容で登録します。よろしいですか？</small>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="店舗名"
                  :value="selectedRequestClientStore ? selectedRequestClientStore.name : ''"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col v-if="parkings.length > 2" cols="12" class="py-0">
                <v-text-field
                  label="対象駐車場"
                  v-model="selectedParking.PARKING_NAME"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="所属"
                  v-model="organization"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="ご利用者名"
                  v-model="ownerName"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="ナンバープレート情報"
                  v-model="numberInfo"
                  readonly
                ></v-text-field>
              </v-col>
              <template v-if="selectedTargetStartDateFormatted || selectedTargetEndDateFormatted">
                <v-col cols="6" class="py-0">
                  <v-text-field
                    v-model="selectedTargetStartDateFormatted"
                    label="開始日"
                    prepend-icon="mdi-calendar"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pl-0 py-0">
                  <v-text-field
                    v-model="selectedTargetEndDateFormatted"
                    label="終了日"
                    prepend-icon="mdi-arrow-right"
                    readonly
                  ></v-text-field>
                </v-col>
              </template>
              <v-col cols="12" class="py-0">
                <v-textarea
                  v-model="note"
                  no-resize
                  rows="4"
                  label="備考"
                  readonly
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ma-0"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small outlined color="blue-grey" @click="dialog = false">修正する</v-btn>
            <v-btn small outlined color="primary" @click="save" :loading="isLoading">登録する</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <SearchInOutRecords
        :selectedRequestClientStore="selectedRequestClientStore"
        :inOutRecordsDialog="inOutRecordsDialog"
        searchFrom="noPaymentRequiredVehicles"
        @applyRecord="applyRecord"
        @closeDialog="closeDialog"
      />
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment"
import qs from 'qs'
import SearchInOutRecords from '../../components/SearchInOutRecords'

export default {
  components: {
    SearchInOutRecords,
  },
  data: () => ({
    showTargetStartDateSelector: false,
    showTargetEndDateSelector: false,
    dialog: false,
    inOutRecordsDialog: false,

    request_client_stores: [],
    selectedRequestClientStore: null,
    noPaymentRequiredVehiclesCount: null,
    isRegisterableNewWhite: true,

    parkings: [],
    selectedParking: {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"},
    ownerInfo: '',
    carPlace: '',
    carClass: '',
    carKana: '',
    carNumber: '',
    selectedTargetStartDate: '',
    selectedTargetStartDateFormatted: '',
    selectedTargetEndDate: '',
    selectedTargetEndDateFormatted: '',
    note: '',
    organization: '',
    ownerName: '',

    isLoading: false,
    agree: false
  }),
  computed: {
    minEndDate () {
      return this.selectedTargetStartDate ? moment(this.selectedTargetStartDate).add(1, 'days').format('yyyy-MM-DD') : null
    },
    maxStartDate () {
      return this.selectedTargetEndDate ? moment(this.selectedTargetEndDate).subtract(1, 'days').format('yyyy-MM-DD') : null
    },
    noteLimit () {
      return 99
    },
    numberInfo () {
      return `${this.carPlace} ${this.carClass} ${this.carKana} ${this.carNumber}`
    }
  },
  watch: {
    selectedRequestClientStore (value) {
      if (value && value.address) {
        this.getParkings()
        this.selectedParking = {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"}
        this.getNoPaymentRequiredVehiclesCount()
        this.inOutRecordsDialog = true
      }
    },
    selectedTargetStartDate (value) {
      if (value) { this.selectedTargetStartDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetEndDate (value) {
      if (value) { this.selectedTargetEndDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetStartDateFormatted (value) {
      if (!value) { this.selectedTargetStartDate = '' }
    },
    selectedTargetEndDateFormatted (value) {
      if (!value) { this.selectedTargetEndDate = '' }
    },
  },
  methods: {
    getNoPaymentRequiredVehiclesCount () {
      const url = `${process.env.VUE_APP_BASE_URL}no_payment_required_vehicles/count?host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}`
      this.axios
        .get(url)
        .then(response => {
          this.noPaymentRequiredVehiclesCount = response.data
          if (this.selectedRequestClientStore.white_limit > 0 && this.noPaymentRequiredVehiclesCount > 0 && this.selectedRequestClientStore.white_limit <= this.noPaymentRequiredVehiclesCount) {
            this.isRegisterableNewWhite = false
          }
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url, { params: { userId: 1} })
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
              this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
          }
        })
        .catch(err => {
          console.log(err)
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    getParkings () {
      const url = `${process.env.VUE_APP_BASE_URL}parkings?&host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}`
      this.axios
        .get(url)
        .then(response => {
          this.parkings = [{PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"}].concat(response.data)
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    async save () {
      this.isLoading = true
      const url = process.env.VUE_APP_BASE_URL + "no_payment_required_vehicles"
      let data = {
        white_list: {
          PLACE: this.carPlace,
          CLASS_NUMBER: this.carClass,
          KANA: this.carKana,
          CAR_NUMBER: ('****' + this.carNumber).slice(-4),
          START_DTE: this.selectedTargetStartDate ? moment(this.selectedTargetStartDate).format('yyyy/MM/DD') : '',
          END_DTE: this.selectedTargetEndDate ? moment(this.selectedTargetEndDate).format('yyyy/MM/DD') : '',
          PARKING_ID: this.selectedParking.PARKING_ID,
          GROUP_ID: 0, // 0: 全体に固定
          KIND_ID: 1, // 1: 従業員に固定
          REGIST_DTE: moment().format('yyyy/MM/DD HH:mm:ss'),
          NOTE: this.note,
          ORGANIZATION: this.organization,
          OWNER_NAME: this.ownerName,
          REGIST_ROUTE_ID: 4
        },
        request_client_store_id: this.selectedRequestClientStore.id,
        request_client_user_id: this.$store.getters.requestClientUserId,
      }
      const options = {
        url: url,
        method: 'POST',
        data: qs.stringify(data),
      }
      await this.axios(options)
      .then(() => {
        this.$router.push({path: "/no-payment-required-vehicles"})
        this.$buefy.toast.open({
          message: `精算不要車両を登録しました`,
          type: "is-info"
        })
      })
      .catch(err => {
        console.log(err.response)
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else if (err.response && err.response.status == 405) {
          this.$buefy.toast.open({
            message: `登録上限に達しているため、新規登録できませんでした。`,
            type: "is-danger",
          })
        } else if (err.response && err.response.status == 406) {
          this.$buefy.toast.open({
            message: `staging環境もしくはローカル開発環境から本番環境への登録・更新操作は許可されていません`,
            type: "is-danger",
          })
        } else if (err.response && err.response.status == 409) {
          this.$buefy.toast.open({
            message: `この車両はすでに登録されています`,
            type: "is-danger",
          })
        } else {
          this.$buefy.toast.open({
            message: `精算不要車両の登録に失敗しました`,
            type: "is-danger",
          })
        }
        this.isLoading = false
        this.dialog = false
      })
    },
    applyRecord (record) {
      this.carPlace = record.PLACE
      this.carClass = record.CLASS_NUMBER
      this.carKana = record.KANA
      this.carNumber = record.CAR_NUMBER.replace(/\*/g,'')
      this.inOutRecordsDialog = false
    },
    closeDialog () {
      this.inOutRecordsDialog = false
    },
  },
  mounted() {
    this.getRequestClientStores()
    this.selectedTargetStartDate = moment().format('yyyy-MM-DD')
  },
}
</script>

<style scoped>
</style>
