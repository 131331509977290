<template>
  <v-container>
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>
          登録済アラート通知車両一覧
        </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="font-weight-black"
          :color="drawer ? 'grey' : 'info'"
          @click.stop="drawer = !drawer"
        >
          <v-icon>
            {{ drawer ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }}
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-container v-if="drawer">
          <v-row>
            <v-col cols="12" lg="4" class="py-0">
              <v-autocomplete
                :outlined="$store.getters.isAdminUser"
                dense
                label="店舗名"
                :items="request_client_stores"
                item-text="name"
                item-value="id"
                v-model="selectedRequestClientStore"
                return-object
                :disabled="!$store.getters.isAdminUser || isLoading"
              ></v-autocomplete>
            </v-col>
            <template v-if="selectedRequestClientStore && selectedRequestClientStore.address">
              <v-col cols="12" lg="4" class="pt-0 pb-md-4 pb-sm-4 pb-xs-4 pb-2">
                <v-text-field v-model="search" append-icon="mdi-magnify" outlined dense clearable hide-details label="車両番号検索"></v-text-field>
              </v-col>
              <v-col cols="12" lg="4" class="pt-0 pb-md-4 pb-sm-4 pb-xs-4 pb-2">
                <div class="d-flex align-center justify-end">
                  <div class="text-caption pl-4">表示件数</div>
                  <div style="width: 100px;" class="pl-2"><v-select outlined dense v-model="itemsPerPage" :items="pageItems" item-text="text" item-value="value" hide-details></v-select></div>
                </div>
              </v-col>
            </template>
          </v-row>
      </v-container>
      <template v-if="selectedRequestClientStore">
        <v-card-text class="pa-3 pt-0">
          <v-data-table
            :items="alertVehicles"
            item-key="id"
            :search="search"
            :page.sync="page"
            :headers="selectHeaders"
            :items-per-page="itemsPerPage"
            mobile-breakpoint="0"
            no-data-text=""
            @page-count="pageCount = $event"
            hide-default-footer
            fixed-header
            dense
          >
            <template v-slot:body="{ items, headers }">
              <tbody v-if="items.length > 0 && !isLoading">
                <tr v-for="item in items" :key="item.BLACK_ID" @click.stop="showDialog(item)" class="table-row">
                  <td class="px-2 text-left hidden-md-and-down">{{ registedDateFormatted(item) }}</td>
                  <td class="px-2 text-left">{{ carInfo(item) }}</td>
                  <td class="text-left hidden-md-and-down">{{ parkingName(item) }}</td>
                </tr>
              </tbody>
              <tbody v-else-if="!isLoading">
                <tr>
                  <td :colspan="headers.length" class="grey--text" style="text-align: center;">No results</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>

          <v-container v-if="!isLoading && selectedRequestClientStore && !selectedRequestClientStore.address" class="text-center my-10">
            店舗address情報が設定されていないため情報を取得できません
          </v-container>
          <v-container v-else-if="!isLoading && alertVehicles.length == 0" class="text-center my-10">
            該当するアラート通知車両がありません
          </v-container>
          <v-container v-if="isLoading" class="text-center my-10">
            <v-progress-circular
              :size="70"
              :width="7"
              color="grey"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-divider class="mt-0 mb-1"></v-divider>
        </v-card-text>
        <v-row v-if="alertVehicles.length != 0" justify="center">
          <v-col cols="10">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-col>
        </v-row>
      </template>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn small outlined color="blue-grey" to="/">戻る</v-btn>
      </v-toolbar>
    </v-card>

    <!-- 詳細画面 -->
    <v-dialog
      scrollable
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          アラート通知車両 登録詳細
        </v-card-title>
        <v-divider class="my-0"></v-divider>
        <v-card-text
          ref="modal_content"
          class="pt-7 pb-3"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="登録日時"
                :value="registedDateFormatted(showedVehicle)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="車両情報"
                :value="carInfo(showedVehicle)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="駐車場情報"
                :value="parkingName(showedVehicle)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="アラートメール件名"
                :value="alertTitle(showedVehicle)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea
                dense
                readonly
                label="アラートメール本文"
                :value="alertText(showedVehicle)"
              ></v-textarea>
            </v-col>
            <template v-if="showedVehicle && showedVehicle.image_1 !== '' && showedVehicle.image_1 !== null">
              <v-col cols="12" class="pt-0">
                <div class="blur" :style="bgimage(showedVehicle)"></div>
              </v-col>
            </template>
            <v-col v-if="filterdIndividualAddresses ||  defaultAlertaddresses" cols="12" class="pb-0">
              <v-textarea
                dense
                readonly
                label="通知先"
                :value="filterdIndividualAddresses ||  defaultAlertaddresses"
                no-resize
                rows="3"
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea
                dense
                readonly
                label="登録理由"
                v-model="showedVehicle.NOTE"
                no-resize
                rows="5"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="blue-grey" @click="close">閉じる</v-btn>
          <v-btn v-if="showedVehicle.KIND_ID == 1" small outlined color="error" @click.stop="confirmDialog = true">登録を削除する</v-btn>
          <v-btn small outlined color="warning" @click="edit">変更する</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 削除確認画面 -->
    <v-dialog
      v-model="confirmDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          削除してよろしいですか？
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="blue-grey" @click="closeConfirm">キャンセル</v-btn>
          <v-btn small outlined color="error" @click="deleteVehicle" :loading="deleteLoading">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment"

export default {
  data: () => ({
    isLoading: false,
    updateLoading: false,
    deleteLoading: false,
    drawer: true,

    request_client_stores: [],
    selectedRequestClientStore: null,
    filterNumber: '',

    defaultAlertaddresses: '',
    filterdIndividualAddresses: '',

    search: '',
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      { text: '登録日時', value: 'REGIST_DTE', sortable: false },
      { text: '車両番号', value: 'NUMBER', sortable: false },
      { text: '対象駐車場', value: '', sortable: false }
    ],
    mobileHeaders: [
      { text: '車両番号', value: 'NUMBER', sortable: false }
    ],

    alertVehicles: [],
    dialog: false,
    showedVehicle: '',

    confirmDialog: false
  }),
  computed: {
    registedDateFormatted () {
      return (value) => {
        return moment(value.REGIST_DTE).format('yyyy/MM/DD HH:mm:ss')
      }
    },
    carInfo () {
      return (value) => {
        return `${value.PLACE} ${value.CLASS_NUMBER} ${value.KANA} ${value.CAR_NUMBER}`
      }
    },
    parkingName () {
      return (value) => {
        return (value.PARKING_COUNT === 1 || value.PARKING_ID === 0) ? '全エリア・全フロア共通' : value.PARKING_NAME
      }
    },
    alertTitle () {
      return (value) => {
        return (value.ALERT_TITLE === '' || value.ALERT_TITLE === null) ? '' : value.ALERT_TITLE
      }
    },
    alertText () {
      return (value) => {
        return (value.ALERT_TEXT === '' || value.ALERT_TEXT === null) ? '' : value.ALERT_TEXT
      }
    },
    selectHeaders: {
      get () { return (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'md') ? this.mobileHeaders : this.headers}
    },
    pageItems: {
      get () { return [ { text: '5', value: 5 },{ text: '10', value: 10 },{ text: '50', value: 50 }, { text: '100', value: 100 }, { text: 'ALL', value: this.alertVehicles.length }] }
    }
  },
  watch: {
    selectedRequestClientStore (value) {
      if (value && value.address) {
        this.getFilteredAlertVehicles()
      }
    },
  },
  methods: {
    getFilteredAlertVehicles () {
      this.page = 1
      this.itemsPerPage = 10
      this.getAlertVehicles()
    },
    async getAlertVehicles () {
      this.isLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}alert_vehicles?host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}`
      await this.axios
        .get(url)
        .then(response => {
          this.alertVehicles = response.data.vehicles
          this.defaultAlertaddresses = response.data.default_alert_addresses.map(address => address.MAIL_ADDRESS).join(', ')
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
      this.isLoading = false
    },
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
            this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
          }
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    showDialog (vehicle) {
      this.showedVehicle = vehicle
      this.formatIndividualAlertAddresses()
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    closeConfirm () {
      this.confirmDialog = false
    },
    formatIndividualAlertAddresses () {
      // 個別指定のアラートアドレスが指定されていれば、ピットデザイン社員を除く
      if (this.showedVehicle.INDIVIDUAL_ALERT_ADDRESSES) {
        this.filterdIndividualAddresses = this.showedVehicle.INDIVIDUAL_ALERT_ADDRESSES.split(',').filter(address => !address.match(/@pitdesign.jp/)).join(', ')
      } else {
        this.filterdIndividualAddresses = ''
      }
    },
    bgimage (value) {
      let buket = ""
      let dir = ""
      if (this.selectedRequestClientStore.address.includes('demo')) {
        buket = "https://test-morita-test.s3.ap-northeast-1.amazonaws.com"
      } else {
        buket = "https://smapa-phase3.s3.ap-northeast-1.amazonaws.com"
      }
      if (this.selectedRequestClientStore.address.includes('.sub.pitdesign.jp')) {
        dir = this.selectedRequestClientStore.address.replace('.sub.pitdesign.jp', '')
      } else {
        dir = this.selectedRequestClientStore.address.replace('.pitdesign.jp', '')
      }
      return `background: center / cover no-repeat url(${buket}/${dir}/black_list/image_1/${value.image_1})`
    },
    async deleteVehicle () {
      this.deleteLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}alert_vehicles/${this.showedVehicle.BLACK_ID}`
      let data = {
        request_client_store_id: this.selectedRequestClientStore.id,
        request_client_user_id: this.$store.getters.requestClientUserId,
      }
      const options = {
        url: url,
        method: 'DELETE',
        data: data,
      }
      await this.axios(options)
      .then(() => {
        this.$buefy.toast.open({
          message: `登録を削除しました`,
          type: "is-info"
        })
        this.getAlertVehicles()
        this.closeConfirm()
        this.close()
        this.deleteLoading = false
      })
      .catch(err => {
        console.log(err.response)
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else if (err.response && err.response.status == 406) {
          this.$buefy.toast.open({
            message: `staging環境もしくはローカル開発環境から本番環境への登録・更新操作は許可されていません`,
            type: "is-danger",
          })
        } else if (err.response && err.response.status == 404) {
          this.$buefy.toast.open({
            message: `対象の車両登録が見つかりません`,
            type: "is-danger",
          })
        } else {
          this.$buefy.toast.open({
            message: `登録削除に失敗しました`,
            type: "is-danger",
          })
        }
        this.closeConfirm()
        this.deleteLoading = false
      })
    },
    edit () {
      this.$router.push({name: "editAlertVehicles", params: { id: this.showedVehicle.BLACK_ID, selectedRequestClientStore: this.selectedRequestClientStore, alertVehicle: this.showedVehicle }, props: true })
    }
  },
  mounted() {
    this.getRequestClientStores()
  }
}
</script>

<style scoped>
.blur {
  position: relative;
  max-width: 100%;
  padding-bottom: 75%;
  background-size: cover;
  overflow: hidden;
}
.blur:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: inherit;
  background-clip: content-box;
  width: 100%;
  height: 100%;
  padding-bottom: 60%;
  filter: blur(5px);
}
</style>
