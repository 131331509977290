<template>
  <v-container>
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>
          CSV出力
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container>
        <ValidationObserver v-slot="{ invalid }">
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-alert dense text type="info" :icon="false" class="mb-0">
                <!-- 機能の説明 -->
                <small>
                  登録済み精算不要車両の利用履歴をCSV形式でダウンロードすることができます。<br>
                </small>
              </v-alert>
            </v-col>
            <v-col cols="12" lg="4" class="py-0">
              <v-autocomplete
                :outlined="$store.getters.isAdminUser"
                dense
                label="店舗名"
                :items="request_client_stores"
                item-text="name"
                item-value="id"
                v-model="selectedRequestClientStore"
                return-object
                :disabled="!$store.getters.isAdminUser || isLoading"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <small>
                期間を指定してください<span class="red--text">(必須)</span>
              </small>
              <v-divider class="mt-0 mb-3"></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-0">
              <v-menu
                v-model="showTargetStartDateSelector"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="開始日"
                    rules="required"
                  >
                    <v-text-field
                      outlined
                      dense
                      v-model="selectedTargetStartDateFormatted"
                      label="開始日"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="selectedTargetStartDate"
                  :max="maxStartDate ? maxStartDate : ''"
                  no-title
                  locale="ja"
                  @input="showTargetStartDateSelector = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="pl-0 py-0">
              <v-menu
                v-model="showTargetEndDateSelector"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="開始日"
                    rules="required"
                  >
                    <v-text-field
                      clearable
                      outlined
                      dense
                      v-model="selectedTargetEndDateFormatted"
                      label="終了日"
                      prepend-icon="mdi-arrow-right"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="selectedTargetEndDate"
                  :min="minEndDate ? minEndDate : ''"
                  no-title
                  locale="ja"
                  @input="showTargetEndDateSelector = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-spacer></v-spacer>
            <v-btn outlined small color="teal" @click.stop="output()" :disabled="invalid">出力</v-btn>
            <v-spacer></v-spacer>
          </v-row>
        </ValidationObserver>
      </v-container>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn small outlined color="blue-grey" to="/">戻る</v-btn>
      </v-toolbar>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment"
import qs from 'qs'

export default {
  data: () => ({
    isLoading: false,

    request_client_stores: [],
    selectedRequestClientStore: null,

    showTargetStartDateSelector: false,
    showTargetEndDateSelector: false,
    selectedTargetStartDate: '',
    selectedTargetStartDateFormatted: '',
    selectedTargetEndDate: '',
    selectedTargetEndDateFormatted: '',

  }),
  computed: {
    minEndDate () {
      return this.selectedTargetStartDate ? moment(this.selectedTargetStartDate).add(1, 'days').format('yyyy-MM-DD') : null
    },
    maxStartDate () {
      return this.selectedTargetEndDate ? moment(this.selectedTargetEndDate).subtract(1, 'days').format('yyyy-MM-DD') : null
    },
  },
  watch: {
    selectedTargetStartDate (value) {
      if (value) { this.selectedTargetStartDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetEndDate (value) {
      if (value) { this.selectedTargetEndDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetStartDateFormatted (value) {
      if (!value) { this.selectedTargetStartDate = '' }
    },
    selectedTargetEndDateFormatted (value) {
      if (!value) { this.selectedTargetEndDate = '' }
    }
  },
  methods: {
    async output () {
      this.isLoading = true
      const url = process.env.VUE_APP_BASE_URL + "no_payment_required_vehicles/output"
      let data = {
        start_date: this.selectedTargetStartDate,
        end_date: this.selectedTargetEndDate,
        name: this.selectedRequestClientStore.name,
        host: this.selectedRequestClientStore.address,
        port: this.selectedRequestClientStore.port
      }
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF])
      const options = {
        url: url,
        method: 'POST',
        data: qs.stringify(data)
      }
      return await this.axios(options)
        .then(res => {
          this.isLoading = false
          const url = URL.createObjectURL(new Blob([bom, res.data], { type: 'text/csv' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', '精算不要車両入出庫一覧.csv')
          document.body.appendChild(link)
          link.click()
        })
        .catch(err => {
          this.isLoading = false
          console.log(err)
          this.$buefy.toast.open({
            message: `出力に失敗しました。`,
            type: "is-danger",
          })
        })
    },
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
            this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
          }
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    }
  },
  mounted() {
    this.getRequestClientStores()
  }
}
</script>

<style scoped>
</style>
