<template>
  <v-alert text type="info" :icon="false" class="mb-4">
    <div class="d-flex align-center px-2">
      <v-card-title class="text-h6 pa-0">【重要】パスワードルール変更のお知らせ</v-card-title>
    </div>
    <v-divider class="ma-0"></v-divider>
    <v-card-text>
      セキュリティ向上のため、2024年8月23日（金）より以下の新しいパスワードルールが適用されます
      <br>
      ・パスワードは8文字以上、英数字を含むもの
      <br>
      つきましては、2024年8月23日（金）までに、ログイン中のメニュー「アカウント設定」より、新しいルールに従ってパスワードを変更していただくようお願いいたします。<br>
      変更が完了していない場合、アカウントにアクセスできなくなる可能性があります。
    </v-card-text>
  </v-alert>
</template>

<script>
export default {
	name: 'ReleaseNote'
}
</script>

<style scoped>
</style>