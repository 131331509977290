<template>
  <v-container>
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>
          登録済入場許可車両一覧
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="font-weight-black"
          :color="drawer ? 'grey' : 'info'"
          @click.stop="drawer = !drawer"
        >
          <v-icon>
            {{ drawer ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }}
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-container v-if="drawer">
        <v-row>
          <v-col cols="12" lg="4" class="pt-0 pb-md-0 pb-4">
            <v-autocomplete
              :outlined="$store.getters.isAdminUser"
              dense
              label="店舗名"
              :items="request_client_stores"
              item-text="name"
              item-value="id"
              v-model="selectedRequestClientStore"
              return-object
              hide-details
              :disabled="!$store.getters.isAdminUser || isLoading"
            ></v-autocomplete>
          </v-col>
          <template v-if="selectedRequestClientStore && selectedRequestClientStore.address">
              <v-col cols="12" lg="4" class="pt-0 pb-4">
                <v-text-field v-model="searchCarNumber" append-icon="mdi-magnify" outlined dense clearable hide-details label="車両番号検索"></v-text-field>
              </v-col>
          </template>
          <template v-if="selectedRequestClientStore && selectedRequestClientStore.address">
            <v-col cols="12" class="py-2 mt-2">
              <v-row>
                <v-col cols="12" lg="4" class="pt-0 pb-2">
                  <v-select outlined dense v-model="filter" :items="filterItems" item-text="text" item-value="value" hide-details label="絞り込み"></v-select>
                </v-col>
                <v-col cols="12" lg="4" class="pt-0 pb-2">
                  <div class="d-flex align-center">
                    <v-select outlined dense v-model="sortBy" :items="sortItems" item-text="text" item-value="value" hide-details label="ソート項目指定" class="rounded-r-0"></v-select>
                    <div style="width: 120px;"><v-select outlined dense v-model="sortDesc" :items="sortDescItems" item-text="text" item-value="value" hide-details background-color="grey lighten-2" class="rounded-l-0"></v-select></div>
                  </div>
                </v-col>
                <v-col cols="12" lg="4" class="pt-0 pb-2">
                  <div class="d-flex align-center justify-end">
                    <div class="text-caption pl-4">表示件数</div>
                    <div style="width: 100px;" class="pl-2"><v-select outlined dense v-model="itemsPerPage" :items="pageItems" item-text="text" item-value="value" hide-details></v-select></div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </template>
        </v-row>
      </v-container>
      <template v-if="selectedRequestClientStore">
        <v-card-text class="pa-3 pt-4">
          <v-data-table
            :items="currentItems"
            item-key="id"
            :search="search"
            :page.sync="page"
            :headers="selectHeaders"
            :items-per-page="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            mobile-breakpoint="0"
            no-data-text=""
            @page-count="pageCount = $event"
            hide-default-footer
            fixed-header
            dense
          >
            <template v-slot:body="{ items, headers }">
              <tbody v-if="items.length > 0 && !isLoading">
                <tr v-for="(item, index) in items" :key="index" @click.stop="showDialog(item)" class="table-row">
                  <td class="text-left">{{ numberInfo(item) }}</td>
                  <td class="text-left" :class="nearDeadlineStyle(item)">{{ effectivePeriod(item) }}</td>
                  <td class="text-left hidden-md-and-down">{{ timeFormatted(item.START_TIME) }}</td>
                  <td class="text-left hidden-md-and-down">{{ timeFormatted(item.END_TIME) }}</td>
                  <td class="text-left hidden-md-and-down">{{ item.PARKING_NAME }}</td>
                </tr>
              </tbody>
              <tbody v-else-if="!isLoading">
                <tr>
                  <td :colspan="headers.length" class="grey--text" style="text-align: center;">No results</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <v-container v-if="!isLoading && selectedRequestClientStore && !selectedRequestClientStore.address" class="text-center my-10">
            店舗address情報が設定されていないため情報を取得できません
          </v-container>
          <v-container v-else-if="!isLoading && gateControlVehicles.length == 0" class="text-center my-10">
            該当する登録済入場許可車両がありません
          </v-container>
          <v-container v-if="isLoading" class="text-center my-10">
            <v-progress-circular
              :size="70"
              :width="7"
              color="grey"
              indeterminate
            ></v-progress-circular>
          </v-container>
          <v-divider class="mt-0 mb-1"></v-divider>
        </v-card-text>
        <v-row v-if="gateControlVehicles.length != 0" justify="center">
          <v-col cols="10">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-col>
        </v-row>
      </template>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn small outlined color="blue-grey" to="/">戻る</v-btn>
      </v-toolbar>
    </v-card>
    <v-dialog
      scrollable
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          入場許可車両 登録詳細
        </v-card-title>
        <v-divider class="my-0"></v-divider>
        <v-card-text
          ref="modal_content"
          class="pt-7 pb-3"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="対象駐車場"
                :value="showedVehicle.PARKING_NAME"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="登録日時"
                :value="registedDateFormatted(showedVehicle)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="車両情報"
                :value="numberInfo(showedVehicle)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="有効期間"
                :value="effectivePeriod(showedVehicle)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="利用時間"
                :value="effectiveTimePeriod(showedVehicle)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea
                dense
                readonly
                label="備考"
                v-model="showedVehicle.NOTE"
                no-resize
                rows="3"
                class="mt-5"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="blue-grey" @click="close">閉じる</v-btn>
          <v-btn small outlined color="error" @click.stop="confirmDialog = true">登録を削除する</v-btn>
          <v-btn small outlined color="warning" @click="edit">変更する</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          削除してよろしいですか？
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="blue-grey" @click="closeConfirm">キャンセル</v-btn>
          <v-btn small outlined color="error" @click="deleteVehicle" :loading="deleteLoading">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment"

export default {
  name: 'IndexGateControlVehicles',
  data: () => ({
    isLoading: false,
    updateLoading: false,
    deleteLoading: false,
    drawer: true,

    request_client_stores: [],
    selectedRequestClientStore: null,
    gateControlVehiclesCount: null,

    gateControlVehicles: [],
    dialog: false,
    showedVehicle: '',
    searchCarNumber: null,
    isSearchCarNumber: false,
    search: '',
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    sortItems: [ { text: '登録日時', value: 'CREATE_DTE'}, { text: '有効期限(開始)', value: 'START_DTE' }, { text: '有効期限(終了)', value: 'END_DTE' }],
    sortDescItems: [ { text: '降順', value: true }, { text: '昇順', value: false }],
    sortBy: '',
    sortDesc: false,
    filterItems: [ { text: '', value: 0 }, { text: '有効期限(1ヶ月前)', value: 1 }, { text: '有効期限(2ヶ月前)', value: 2 }],
    filter: 0,
    confirmDialog: false,
    parkingInfo: [],
    showParkingInfo: false
  }),
  computed: {
    registedDateFormatted () {
      return (value) => {
        return moment(value.CREATE_DTE).format('yyyy/MM/DD HH:mm')
      }
    },
    timeFormatted () {
      return (value) => {
        return value !== null ? moment(value).format('HH:mm') : ''
      }
    },
    numberInfo () {
      return (value) => {
        return `${value.PLACE} ${value.CLASS_NUMBER} ${value.KANA} ${value.CAR_NUMBER}`
      }
    },
    effectivePeriod () {
      return (value) => {
        let endDte = ''
        if (value.END_DTE && (moment(value.END_DTE).format('YY/MM/DD') != '99/12/31')) {
          endDte = moment(value.END_DTE).format('YY/MM/DD')
        }
        if (value.START_DTE && value.END_DTE) {
          return `${moment(value.START_DTE).format('YY/MM/DD')} 〜 ${endDte}`
        } else if (value.START_DTE) {
          return `${moment(value.START_DTE).format('YY/MM/DD')} 〜`
        } else if (value.END_DTE) {
          return `〜 ${endDte}`
        } else {
          return '設定なし'
        }
      }
    },
    effectiveTimePeriod () {
      return (value) => {
        return `${moment(value.START_TIME).format('HH:mm')} 〜 ${moment(value.END_TIME).format('HH:mm')}`
      }
    },
    nearDeadlineStyle () {
      return (value) => {
        return value.END_DTE !== null && moment(value.END_DTE).isSameOrBefore(moment().add(1, 'months')) ? 'near-deadline': ''
      }
    },
    selectHeaders: {
      get () { return (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'md') ? this.mobileHeaders : this.headers}
    },
    headers () {
      return [
        { text: '車両番号', value: 'NUMBER', sortable: false, filterable: true },
        { text: '有効期間', value: '', sortable: false, filterable: false },
        { text: '開始時刻', value: 'START_TIME', sortable: false, filterable: false },
        { text: '終了時刻', value: 'END_TIME', sortable: false, filterable: false },
        { text: '対象駐車場', value: 'PARKING_NAME', sortable: false, filterable: false }
      ]
    },
    mobileHeaders () {
      return [
        { text: '車両番号', value: 'NUMBER', sortable: false, filterable: true },
        { text: '有効期間', value: '', sortable: false, filterable: false }
      ]
    },
    pageItems: {
      get () { return [ { text: '5', value: 5 },{ text: '10', value: 10 },{ text: '50', value: 50 }, { text: '100', value: 100 }, { text: 'ALL', value: this.gateControlVehiclesCount }] }
    },
    currentItems () {
      let selectItems = []
      if (this.isSearchCarNumber) {
        selectItems = this.gateControlVehicles.filter((vehicle) => vehicle.NUMBER && vehicle.NUMBER.indexOf(this.searchCarNumber) !== -1)
      } else {
        selectItems = this.gateControlVehicles
      }
      if (this.filter === 0) {
        return selectItems
      } else if (this.filter === 1) {
        return selectItems.filter((vehicle) => vehicle.END_DTE !== null && moment(vehicle.END_DTE).isBetween(moment(), moment().add(1, 'months'), null, '[]'))
      } else {
        return selectItems.filter((vehicle) => vehicle.END_DTE !== null && moment(vehicle.END_DTE).isAfter(moment()) && moment(vehicle.END_DTE).isBetween(moment(), moment().add(2, 'months'), null, '[]'))
      }
    }
  },
  watch: {
    selectedRequestClientStore (value) {
      if (value && value.address) {
        this.getGateControlVehicles()
      }
    },
    searchCarNumber (value) {
      if (value === '' || value === null) {
        this.isSearchCarNumber = false
      } else {
        this.isSearchCarNumber = true
      }
    }
  },
  methods: {
    validateUserAccess() {
      if (!this.$store.getters.isAdminUser && !this.$store.getters.isGateControlStore) {
        this.$router.push({ path: '/' });
      }
    },
    getFilteredGateControlVehicles () {
      this.page = 1
      this.getGateControlVehicles()
    },
    async getGateControlVehicles () {
      this.filter = 0
      this.sortBy = ''
      this.itemsPerPage = 10
      this.isLoading = true
      const data = {
        address: this.selectedRequestClientStore.address,
        request_client_store_id: this.selectedRequestClientStore.id,
        parkingId: 0
      }
      const url = `${process.env.VUE_APP_BASE_URL}gate_control_car_lists`
      await this.axios
        .get(url, {params: data})
        .then(response => {
          console.log(response)
          this.gateControlVehicles = response.data
          this.gateControlVehiclesCount = this.gateControlVehicles.length
          this.showParkingInfo = true
        })
        .catch(err => { 
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
      this.isLoading = false
    },
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
            this.selectedRequestClientStore = this.request_client_stores.find(store => store.id == this.$store.getters.userRequestClientStoreId)
          }
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    showDialog (vehicle) {
      this.showedVehicle = vehicle
      this.dialog = true
    },
    async deleteVehicle () {
      this.deleteLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}gate_control_car_lists/${this.showedVehicle.ID}`
      let data = {
        request_client_store_id: this.selectedRequestClientStore.id,
        address: this.selectedRequestClientStore.address,
        parkingId: this.showedVehicle.PARKING_ID,
        request_client_user_id: this.$store.getters.requestClientUserId,
      }
      const options = {
        url: url,
        method: 'DELETE',
        data: data,
      }
      await this.axios(options)
      .then(() => {
        this.$buefy.toast.open({
          message: `登録を削除しました`,
          type: "is-info"
        })
        this.deleteLoading = false
        this.getGateControlVehicles()
        this.closeConfirm()
        this.close()
      })
      .catch(err => {
        console.log(err.response)
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else if (err.response && err.response.status == 406) {
          this.$buefy.toast.open({
            message: `staging環境もしくはローカル開発環境から本番環境への登録・更新操作は許可されていません`,
            type: "is-danger",
          })
        } else if (err.response && err.response.status == 404) {
          this.$buefy.toast.open({
            message: `対象の車両登録が見つかりません`,
            type: "is-danger",
          })

        } else {
          this.$buefy.toast.open({
            message: `登録削除に失敗しました`,
            type: "is-danger",
          })
        }
        this.getGateControlVehicles()
        this.closeConfirm()
        this.close()
        this.deleteLoading = false
      })
    },
    close () {
      this.dialog = false
    },
    closeConfirm () {
      this.confirmDialog = false
    },
    edit () {
      this.$router.push({name: "editGateControlVehicles", params: { id: this.showedVehicle.ID, selectedRequestClientStore: this.selectedRequestClientStore, gateControlVehicle: this.showedVehicle }, props: true })
    }
  },
  mounted() {
    this.validateUserAccess()
    this.getRequestClientStores()
  }
}
</script>

<style scoped>
.near-deadline { background: #F95F5333; color: #F95F53; }
.table-row { cursor: pointer; }
.custom-table { border-collapse: collapse; width: 100%; }
.custom-table th,.custom-table td { border: 1px solid #ccc; padding: 8px; text-align: center; vertical-align: middle; }
.header-cell { background-color: #2196f32b; }
.info-cell { background-color: #2196f32b; }
.custom-table tbody tr:hover { background-color: transparent !important; }
</style>
