<template>
  <v-container>
      <v-card tile>
        <v-toolbar flat>
          <v-toolbar-title>
            障害者一般車両 新規登録
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" class="pt-0">
                <v-alert dense text type="info" :icon="false" class="mb-2">
                  <small>
                    こちらは障害者一般車両の新規登録画面です。<br>
                    車両の情報を入力して新規登録できます。
                  </small>
                </v-alert>
              </v-col>
          </v-row>
        </v-card-text>

        <!-- 登録フォーム -->
        <ValidationObserver v-slot="{ invalid }">
          <v-container v-if="drawer">
            <v-row>
              <v-col cols="12" lg="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="店舗名"
                  rules="required"
                >
                  <v-autocomplete
                    :outlined="$store.getters.isAdminUser"
                    dense
                    label="店舗名"
                    :items="request_client_stores"
                    item-text="name"
                    item-value="id"
                    v-model="selectedRequestClientStore"
                    return-object
                    :disabled="!$store.getters.isAdminUser"
                    :error-messages="errors"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>

              <!-- <v-col v-if="selectedRequestClientStore && parkings.length > 2" cols="12" lg="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="対象駐車場"
                  rules="required"
                >
                  <v-select
                    outlined
                    dense
                    v-model="selectedParking"
                    return-object
                    :items="parkings"
                    item-text="PARKING_NAME"
                    item-value="PARKING_ID"
                    label="対象駐車場"
                    :error-messages="errors"
                  ></v-select>
                </ValidationProvider>
              </v-col> -->

            </v-row>
            <template v-if="selectedRequestClientStore">
              <v-row>
                <v-col cols="12" class="py-0">
                  <small>
                    障害者一般車両の情報を入力してください<span class="red--text">(必須)</span>
                  </small>
                  <v-divider class="mt-0 mb-3"></v-divider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="3" class="py-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="地域"
                    rules="required|car_place"
                  >
                    <v-text-field
                      outlined
                      dense
                      label="地域 (例: 旭川)"
                      v-model="carPlace"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" lg="3" class="py-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="クラス"
                    rules="required|car_class"
                  >
                    <v-text-field
                      outlined
                      dense
                      label="クラス (例: 500)"
                      v-model="carClass"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" lg="3" class="py-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="かな"
                    rules="required|car_kana"
                  >
                    <v-text-field
                      outlined
                      dense
                      label="かな (例: あ)"
                      v-model="carKana"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" lg="3" class="py-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="4桁ナンバー"
                    rules="required|car_number"
                  >
                    <v-text-field
                      outlined
                      dense
                      label="4桁ナンバー (例: 3, 88, 456)"
                      v-model="carNumber"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <template v-if="isAccessibleAccount">
                <v-row>
                  <v-col cols="12" class="py-0">
                    <small>申請者名を入力してください<span class="pl-1" v-if="$store.getters.isAdminUser">※レイクタウン,幕張新都心のみ,浦和美園,りんくう泉南</span></small>
                    <v-divider class="mt-0 mb-3"></v-divider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" cols="12" class="py-0">
                    <v-text-field
                      outlined
                      dense
                      v-model="name"
                      label="氏名"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <small>登録事由を選択してください<span class="red--text">(必須)</span><span class="pl-1" v-if="$store.getters.isAdminUser">※レイクタウン、幕張新都心のみ,浦和美園,りんくう泉南</span></small>
                    <v-divider class="mt-0 mb-3"></v-divider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" cols="12" class="py-0">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="登録事由"
                      rules="required"
                    >
                      <v-select
                        outlined
                        dense
                        v-model="reason"
                        :items="storeAccessibleVehicleReasons"
                        item-text="name"
                        item-value="name"
                        label="登録事由"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col v-if="reason === 'その他'" md="6" cols="12" class="py-0">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="登録事由(その他)"
                      :rules="reason === 'その他' ? `required` : ``"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="otherReason"
                        label="その他の場合は入力してください。"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </template>

              <v-row>
                <v-col cols="12" class="py-0">
                  <small>
                    有効期間の開始日・終了日を選択してください
                  </small>
                  <v-divider class="mt-0 mb-3"></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="py-0">
                  <v-menu
                    v-model="showTargetStartDateSelector"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <ValidationProvider
                        v-slot="{ errors }"
                        name="開始日"
                        rules="required"
                      > -->
                        <v-text-field
                          clearable
                          outlined
                          dense
                          v-model="selectedTargetStartDateFormatted"
                          label="開始日"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="errors"
                        ></v-text-field>
                      <!-- </ValidationProvider> -->
                    </template>
                    <v-date-picker
                      v-model="selectedTargetStartDate"
                      :max="maxStartDate ? maxStartDate : ''"
                      no-title
                      locale="ja"
                      @input="showTargetStartDateSelector = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" class="pl-0 py-0">
                  <v-menu
                    v-model="showTargetEndDateSelector"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        clearable
                        outlined
                        dense
                        v-model="selectedTargetEndDateFormatted"
                        label="終了日"
                        prepend-icon="mdi-arrow-right"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="selectedTargetEndDate"
                      :min="minEndDate ? minEndDate : ''"
                      no-title
                      locale="ja"
                      @input="showTargetEndDateSelector = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
              <v-col>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="備考"
                  :rules="`max:${noteLimit}`"
                >
                  <v-textarea
                    outlined
                    dense
                    v-model="note"
                    no-resize
                    rows="4"
                    label="備考"
                    :counter="noteLimit"
                    :error-messages="errors"
                  ></v-textarea>
                </ValidationProvider>
              </v-col>
            </v-row>




            </template>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small outlined color="blue-gray" class="mr-2" to="/">戻る</v-btn>
            <v-btn small outlined color="primary" :disabled="invalid" @click.stop="dialog = true">登録内容確認</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </ValidationObserver>

        <!-- 確認フォーム -->
        <v-dialog
          v-model="dialog"
          max-width="500px"
          scrollable
        >
          <v-card>
            <v-card-title>登録内容確認</v-card-title>
            <v-divider class="my-0"></v-divider>
            <v-card-text>
              <v-row>
                <small class="my-5">以下の内容で登録します。よろしいですか？</small>

                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="ナンバープレート情報"
                    v-model="numberInfo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="py-0">
                  <div class="v-text-field v-text-field--dense v-text-field--readonly">
                    <label class="v-label text-caption">有効期間</label>
                    <div class="v-input__control">
                      <div class="v-input__slot">
                        <div v-html="validityPeriod" class="effective-period"></div>
                      </div>
                    </div>
                  </div>
                </v-col>

                <template v-if="isAccessibleAccount">
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      label="申請者名"
                      v-model="name"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      label="登録事由"
                      v-model="reason"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="reason === 'その他'" cols="12" class="py-0">
                    <v-text-field
                      label="その他理由"
                      v-model="otherReason"
                      readonly
                    ></v-text-field>
                  </v-col>
                </template>

                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="備考"
                    v-model="note"
                    readonly
                  ></v-text-field>
                </v-col>

              </v-row>
            </v-card-text>
            <v-divider class="ma-0"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
                small
                outlined
                color="brue-gray"
                class="mr-2 mb-5"
                @click="dialog = false"
              >
                修正する
              </v-btn>
              <v-btn
                small
                outlined
                color="primary"
                class="mb-5"
                @click="submitForm"
                :loading="isLoading"
              >
                登録する
              </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          </v-card>
        </v-dialog>

      </v-card>
  </v-container>
</template>
<script>
import moment from "moment"
import qs from 'qs'
export default {
  name: 'NewAccessibleVehicles',
  data: () => ({
    request_client_stores: [],
    selectedRequestClientStore: null,
    accessibleVehicleReasons: [],
    expiryYear: null,
    expiryDay: null,
    errors: [],

    drawer: true,
    dialog: false,
    isLoading: false,

    carPlace: '',
    carClass: '',
    carKana: '',
    carNumber: '',
    // selectedParking: {PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"},

    // parkings: [],

    showTargetStartDateSelector: false,
    showTargetEndDateSelector: false,
    selectedTargetStartDate: '',
    selectedTargetStartDateFormatted: '',
    selectedTargetEndDate: '',
    selectedTargetEndDateFormatted: '',
    note: '',
    reason: null,
    otherReason: '',
    name: ''
  }),
  computed: {
    minEndDate () {
      return this.selectedTargetStartDate ? moment(this.selectedTargetStartDate).add(1, 'days').format('YYYY-MM-DD') : null
    },
    maxStartDate () {
      return this.selectedTargetEndDate ? moment(this.selectedTargetEndDate).subtract(1, 'days').format('YYYY-MM-DD') : null
    },
    noteLimit () {
      return 99
    },
    numberInfo () {
      return `${this.carPlace} ${this.carClass} ${this.carKana} ${this.carNumber}`
    },
    validityPeriod () {
      const whitespace = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
      if (this.selectedTargetStartDate && this.selectedTargetEndDate) {
        return `${this.selectedTargetStartDateFormatted} 〜 ${this.selectedTargetEndDateFormatted}`
      } else if (this.selectedTargetStartDate) {
        return `${this.selectedTargetStartDateFormatted} 〜`
      } else if (this.selectedTargetEndDate) {
        return `${whitespace}〜 ${this.selectedTargetEndDateFormatted}`
      } else {
        return ''
      }
    },
    storeAccessibleVehicleReasons () {
      return this.accessibleVehicleReasons.length > 0 ? this.accessibleVehicleReasons.filter((item) => item.request_client_store_id === this.selectedRequestClientStore.id) : []
    },
    isAccessibleAccount () {
      return (
        this.$store.getters.isAdminUser ||
        this.$store.getters.canManageAccessibleDetail
        // this.$store.getters.isRakeTownAdmin ||
        // this.$store.getters.isAeonMakuhariAccessible ||
        // this.$store.getters.isAeonMakuhariUser ||
        // this.$store.getters.isAeonUrawamisonoAccessible ||
        // this.$store.getters.isAeonRinkuizumiminamiAccessible
      )
    }
  },
  watch: {
    selectedRequestClientStore(value) {
      if (value) {
        this.expiryYear = this.selectedRequestClientStore.expiry_year
        this.expiryDay = this.selectedRequestClientStore.expiry_day
      }
    },
    selectedTargetStartDate (value) {
      if (value) {
        this.selectedTargetStartDateFormatted = moment(value).format('YY/MM/DD')
        let targetDay = moment(value)
        let calculateFormatYear = this.expiryYear ? targetDay.add(this.expiryYear, 'years') : targetDay
        if (this.expiryDay && this.expiryDay > 0) {
          this.selectedTargetEndDate = calculateFormatYear.add(this.expiryDay, 'day').format('YYYY-MM-DD')
        } else if (this.expiryDay && this.expiryDay <= 0) {
          this.selectedTargetEndDate = calculateFormatYear.subtract(Math.abs(this.expiryDay), 'day').format('YYYY-MM-DD')
        } else if (this.expiryYear) {
          this.selectedTargetEndDate = calculateFormatYear.format('YYYY-MM-DD')
        }
      }
    },
    selectedTargetEndDate (value) {
      if (value) { this.selectedTargetEndDateFormatted = moment(value).format('YY/MM/DD') }
    },
    selectedTargetStartDateFormatted (value) {
      if (!value) { this.selectedTargetStartDate = '' }
    },
    selectedTargetEndDateFormatted (value) {
      if (!value) { this.selectedTargetEndDate = '' }
    },
  },
  methods: {
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
            this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
            this.expiryYear = this.selectedRequestClientStore.expiry_year
            this.expiryDay = this.selectedRequestClientStore.expiry_day
          }
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    getAccessibleVehicleReasons () {
      const url = process.env.VUE_APP_BASE_URL + "accessible_vehicle_reasons"
      this.axios
        .get(url)
        .then(response => {
          this.accessibleVehicleReasons = response.data
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `登録事由の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    // getParkings() {
    //   const url = `${process.env.VUE_APP_BASE_URL}parkings?&host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}`
    //   this.axios
    //     .get(url)
    //     .then(response => {
    //       this.parkings = [{PARKING_ID: 0, PARKING_NAME: "全エリア・全フロア共通"}].concat(response.data)
    //     })
    //     .catch(err => {
    //       if (err.response && err.response.status == 401) {
    //         this.$buefy.toast.open({
    //           message: `認証に失敗しました。ログインし直してください。`,
    //           type: "is-danger",
    //         })
    //         this.$store.commit('clearUser')
    //         this.$router.push({path: "/login"})
    //       } else {
    //         this.$buefy.toast.open({
    //           message: `情報の取得に失敗しました`,
    //           type: "is-danger",
    //         })
    //       }
    //     })
    // },
    async submitForm() {
      this.isLoading = true
      const data = {
        accessible_vehicle:
        {
          request_client_user_id: this.$store.getters.requestClientUserId,
          regist_route_id: 4, // 依頼ツールのID

          // parking_id: 1, // 仙台空港用にIDを1で固定
          place: this.carPlace,
          class_number: this.carClass,
          kana: this.carKana,
          car_number: ('****' + this.carNumber).slice(-4),
          start_datetime: this.selectedTargetStartDate ? moment(this.selectedTargetStartDate).format('YYYY-MM-DD 00:00:00') : '1000-01-01 00:00:00',
          end_datetime: this.selectedTargetEndDate ? moment(this.selectedTargetEndDate).add(1, 'days').format('YYYY-MM-DD 00:00:00') : '9999-12-31 00:00:00',
          note: this.note,
          applicant_name: this.name,
          reason: this.reason === 'その他' ? this.otherReason : this.reason 
        }
      }
      const url = `${process.env.VUE_APP_BASE_URL}accessible_vehicles?host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}`

      this.axios.post(url, qs.stringify(data))
        .then((res) => {
          if (res.data.status === 'conflict') {
            this.$buefy.toast.open({
              message: `この車両はすでに登録されています`,
              type: "is-danger",
            })
          } else if (res.data.status === 'not_found') {
            this.$buefy.toast.open({
              message: `駐車場が見つかりませんでした。`,
              type: "is-danger",
            })
          } else {
            // 一覧に戻る → メッセージを出す
            this.$router.push({path: "/accessible-vehicles"})
            this.$buefy.toast.open({
              message: `障害者一般車両を登録しました`,
              type: "is-info"
            })
          }
        })
        .catch((error) => {
          console.log(error.message)
          if (error.response && error.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else if (error.response && error.response.status == 406) {
            this.$buefy.toast.open({
              message: `staging環境もしくはローカル開発環境から本番環境への登録・更新操作は許可されていません`,
              type: "is-danger",
            })
          } else {
            this.$buefy.toast.open({
              message: `障害者一般車両の登録に失敗しました`,
              type: "is-danger",
            })
          }
        })
        .finally(() => {
          this.isLoading = false
          this.dialog = false
        })
    },
  },
  mounted() {
    this.getAccessibleVehicleReasons()
    this.getRequestClientStores()
  }
}
</script>
<style>
.effective-period {
  color: black;
  font-size: 16px;
}
</style>
