<template>
  <v-container>
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>
          駐車料金未払い車両アラート 一覧
        </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="font-weight-black"
          :color="drawer ? 'grey' : 'info'"
          @click.stop="drawer = !drawer"
        >
          <v-icon>
            {{ drawer ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }}
          </v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="pt-0">
              <v-alert dense text type="info" :icon="false" class="mb-2">
                <small>
                  こちらは駐車料金未払い車両アラートの一覧です。<br>
                  対象駐車場による絞り込みができます。
                </small>
              </v-alert>
            </v-col>
        </v-row>
      </v-card-text>

      <v-container v-if="drawer">
        <v-row>
          <v-col cols="12" lg="6" class="py-0">
            <ValidationProvider
              v-slot="{ errors }"
              name="店舗名"
              rules="required"
            >
              <v-autocomplete
                :outlined="$store.getters.isAdminUser"
                dense
                label="店舗名"
                :items="request_client_stores"
                item-text="name"
                item-value="id"
                v-model="selectedRequestClientStore"
                return-object
                :disabled="!$store.getters.isAdminUser"
                :error-messages="errors"
              ></v-autocomplete>
            </ValidationProvider>
          </v-col>

          <v-col cols="12" lg="6" class="py-0" v-if="selectedRequestClientStore && selectedRequestClientStore.address">
            <v-select
            label="対象駐車場"
            v-model="filterParkingName"
            :items="filterParkingNameItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details
            ></v-select>
          </v-col>
        </v-row>
        <template v-if="selectedRequestClientStore && selectedRequestClientStore.address">
          <v-row>
            <v-col cols="12" class="d-flex justify-end align-center">
              <div v-show="lastUpdated" class="pl-4 pr-2"><span class="text-caption pl-4">最終更新 </span>{{ lastUpdated }}</div>
              <v-btn class="mr-5" outlined color="primary" @click="refreshData" :disabled="isLoading">更新</v-btn>
              <div class="d-flex align-center justify-end">
                <div class="text-caption pl-4">表示件数</div>
                <div style="width: 100px;" class="pl-2">
                  <v-select
                    outlined
                    dense
                    v-model="itemsPerPage"
                    :items="pageItems"
                    item-text="text"
                    item-value="value"
                    hide-details
                  ></v-select>
                </div>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>

      <template v-if="selectedRequestClientStore">
        <v-container>
          <v-card-text class="pa-3 pt-0">
            <v-data-table
              :items="currentItems"
              item-key="id"
              :page.sync="page"
              :headers="selectHeaders"
              :items-per-page="itemsPerPage"
              mobile-breakpoint="0"
              no-data-text=""
              @page-count="pageCount = $event"
              hide-default-footer
              fixed-header
              dense
            >
              <template v-slot:body="{ items, headers }">
                <tbody v-if="items && items.length > 0 && !isLoading">
                  <tr v-for="item in items" :key="item.id" @click.stop="showDialog(item)" class="table-row">
                    <td class="px-2 text-left hidden-md-and-down">{{ item.HAPPEN_DATETIME }}</td>
                    <td class="px-2 text-left hidden-md-and-down">{{ formatDateTime(item.ENTRANT_TIME) }}</td>
                    <td class="px-2 text-left"                   >{{ item.FULL_INFO }}</td>
                    <td class="px-2 text-left"                   >{{ showParkingName(item) }}</td>
                  </tr>
                </tbody>
                <tbody v-else-if="!isLoading">
                  <tr>
                    <td :colspan="headers.length" class="grey--text" style="text-align: center;">該当無し</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>

            <v-container v-if="!isLoading && selectedRequestClientStore && !selectedRequestClientStore.address" class="text-center my-10">
              店舗address情報が設定されていないため情報を取得できません
            </v-container>
            <v-container v-else-if="!isLoading && unpaidAlertNotifications.length == 0" class="text-center my-10">
              該当する駐車料金未払い車両がありません
            </v-container>

            <v-container v-if="isLoading" class="text-center my-10">
              <v-progress-circular
                :size="70"
                :width="7"
                color="grey"
                indeterminate
              ></v-progress-circular>
            </v-container>
          </v-card-text>
          <v-row v-if="unpaidAlertNotifications.length != 0" justify="center">
          <v-col cols="10">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-col>
        </v-row>
        </v-container>
      </template>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn small outlined color="blue-grey" to="/">戻る</v-btn>
      </v-toolbar>
    </v-card>

    <v-dialog
      scrollable
      v-model="dialog"
      max-width="500px"
      >
      <v-card>
        <v-card-title>
          駐車料金未払い車両アラート 登録詳細
        </v-card-title>
        <v-divider class="my-0"></v-divider>
        <v-card-text
          ref="modal_content"
          class="pt-7 pb-3"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="車両番号"
                :value="showedItem.FULL_INFO"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <small>車両画像</small>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-card >
                <div class="blur" :style="bgimage"></div>
              </v-card>
            </v-col>
            <v-col cols="12" class="mt-4">
              <v-text-field
                dense
                readonly
                label="入庫日時"
                :value="formatDateTime(showedItem.ENTRANT_TIME)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                dense
                readonly
                label="エリア"
                :value="showedItemAreaName"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mb-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="blue-grey" @click="close">閉じる</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import moment from "moment"
export default {
  name: 'unpaidAlertNotifications',
  data() {
    return {
      isLoading: false,
      updateLoading: false,
      deleteLoading: false,
      drawer: true,

      request_client_stores: [],
      selectedRequestClientStore: null,
      errors: [],

      unpaidAlertNotifications: [],

      dialog: false,
      showedItem: '',
      showedItemImagePath: '',
      showedItemAreaName: '',

      page: 1,
      pageCount: 0,
      itemsPerPage: 10,

      lastUpdated: null,

      headers: [
        { text: '発生日時',   value: 'HAPPEN_DATETIME', sortable: true  },
        { text: '入庫日時',   value: 'ENTRANT_TIME',    sortable: false },
        { text: '車両番号',   value: 'FULL_INFO',      sortable: false },
        { text: '対象駐車場', value: 'PARKING_ID',      sortable: false },
      ],
      mobileHeaders: [
        { text: '車両番号',   value: 'FULL_INFO',      sortable: false },
        { text: '対象駐車場', value: 'PARKING_ID',      sortable: false },
        
      ],
      filterParkingName: 0,
      filterParkingNameItems: [
        { text: '全て', value: 0 },
      ],
    }
  },
  watch: {
    selectedRequestClientStore (value) {
      if (value && value.address) {
        this.getFilteredUnpaidAlertNotifications()
      }
    },
  },
  methods: {
    getFilteredUnpaidAlertNotifications () {
      this.page = 1
      this.itemsPerPage = 10
      this.getUnpaidAlertNotifications()
    },
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
            this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
          }
          })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    async getUnpaidAlertNotifications() {
      this.isLoading = true
      this.unpaidAlertNotifications = []
      this.lastUpdated = moment().format('YYYY/MM/DD HH:mm:ss')
      const url = `${process.env.VUE_APP_BASE_URL}unpaid_alert_notifications?host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}`
      await this.axios
      .get(url)
      .then(response => {
        this.unpaidAlertNotifications = response.data.unpaid_alert_notifications
        this.setFilterParkingNameItems(response.data.unpaid_alert_notifications)
      })
      .catch(err => {
        console.log(err)
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({ path: "/login" })
        } else {
          this.$buefy.toast.open({
            message: `情報の取得に失敗しました`,
            type: "is-danger",
          })
        }
      })
      this.isLoading = false
    },
    refreshData() {
      this.getUnpaidAlertNotifications()
    },
    setFilterParkingNameItems(notifications) {
      // notificationsをPARKING_NAMEが存在するもののみにする
      const notificationsWithParkingName = notifications.filter(notification => notification.PARKING_NAME)
      // { text: '駐車場名', value: notification.PARKING_ID } のオブジェクト形式に変換 ※多数の重複あり
      const parkingNameItems = notificationsWithParkingName.map(notification => {
        return { text: this.showParkingName(notification), value: notification.PARKING_ID }
      })
      // 駐車場名のみを抜き出して配列を作成 ['駐車場A', '駐車場B', '駐車場C', '駐車場A']
      const parkingNames = parkingNameItems.map(item => item.text);
      // Setで駐車場名の重複を排除 { '駐車場A', '駐車場B', '駐車場C' }
      const SetUniqueParkingNames = new Set(parkingNames);
      // Setを配列に変換 ['駐車場A', '駐車場B', '駐車場C']
      const ArrayUniqueParkingNames = Array.from(SetUniqueParkingNames);
      // 配列を反復させて最初に一致するオブジェクトのみを取得
      const uniqueItems = ArrayUniqueParkingNames.map(parkingName => {
        return parkingNameItems.find(item => item.text === parkingName);
      });
      // 空の選択肢を先頭に置いて、重複の無いオブジェクトを追加
      this.filterParkingNameItems = [{ text: '全て', value: 0 }, ...uniqueItems];
    },
    async showDialog(item) {
      this.showedItem = item
      await this.fetchDetailedInfo(item)
      this.dialog = true
    },
    async fetchDetailedInfo(item) {
      const url = `${process.env.VUE_APP_BASE_URL}unpaid_alert_notifications/fetch_detailed_info`
      const data = {
        host: this.selectedRequestClientStore.address,
        port: this.selectedRequestClientStore.port,
        PARKING_ID: item.PARKING_ID,
        ENTRANT_DTE: moment(item.ENTRANT_TIME, 'YYYY年MM月DD日 HH:mm:ss').format('YYYY-MM-DD 00:00:00'),
        ENTRANT_TIME: moment(item.ENTRANT_TIME, 'YYYY年MM月DD日 HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
        PLACE: item.PLACE,
        CLASS_NUMBER: item.CLASS_NUMBER,
        KANA: item.KANA,
        CAR_NUMBER: item.CAR_NUMBER,
      }
      try {
        const response = await this.axios.get(url, { params: data })
        if (response.data && response.data.detailed_info && response.data.detailed_info.length > 0) {
          const detailed_info = response.data.detailed_info[0]
          this.showedItemImagePath = detailed_info.IMAGE_DATA_OBJECT_PATH
          this.showedItemAreaName = detailed_info.AREA_NAME
        }
      } catch (err) {
        console.log(err)
        this.$buefy.toast.open({
          message: `情報の取得に失敗しました`,
          type: "is-danger"
        })
      }
    },
    close() {
      this.dialog = false
      this.showedItem = ''
      this.showedItemImagePath = ''
      this.showedItemAreaName = ''
    },
  },

  computed: {
    selectHeaders: {
      get() {
        return (
          this.$vuetify.breakpoint.name === 'xs' ||
          this.$vuetify.breakpoint.name === 'sm' ||
          this.$vuetify.breakpoint.name === 'md'
        ) ? this.mobileHeaders : this.headers
      }
    },
    pageItems: {
      get() {
        return [
          { text: '5', value: 5 },
          { text: '10', value: 10 },
          { text: '50', value: 50 },
          { text: '100', value: 100 },
          { text: 'ALL', value: this.unpaidAlertNotifications.length }
        ]
      }
    },
    currentItems() {
      if (!this.unpaidAlertNotifications) {
        return []
      }
      // 絞り込み条件でフィルタリング
      const filteredItems =  this.unpaidAlertNotifications.filter(item => {
        // 対象駐車場  いずれかに該当したらtrueを返す
        const isParkingNameMatched = (this.filterParkingName === 0) || (item.PARKING_ID === this.filterParkingName)
        return isParkingNameMatched
      })
      return filteredItems
    },
    formatDateTime() {
      return (value) => {
        return moment(value, 'YYYY年MM月DD日 HH:mm:ss').format('yyyy/MM/DD HH:mm:ss')
      }
    },
    showParkingName() {
      return (value) => {
        return (value.PARKING_COUNT === 1 || value.PARKING_ID === 0) ? '全エリア・全フロア共通' : value.PARKING_NAME
      }
    },
    bgimage() {
      return `background: center / cover no-repeat url(${this.showedItemImagePath});`
    },
  },
  mounted() {
    this.getRequestClientStores()
  }
}
</script>

<style scoped>
.blur {
  position: relative;
  width: 450px;
  height: 300px;
  background-size: cover;
  overflow: hidden;
}
.blur:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: inherit;
  background-clip: content-box;
  width: 100%;
  height: 100%;
  padding-bottom: 60%;
  filter: blur(5px);
}
</style>
