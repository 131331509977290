<template>
  <v-dialog
    v-model="inOutRecordsDialog"
    max-width="800px"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title class="mb-3 py-1 px-3 blue-grey lighten-3">
        ▶︎利用履歴から車両を検索
      </v-card-title>
      <ValidationObserver v-slot="{ invalid }">
        <v-row no-gutters>
          <v-col cols="4" class="ml-3">
            <v-menu
              v-model="showFilterDateSelector"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="入庫日"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    dense
                    v-model="filterDateFormatted"
                    label="入庫日"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker
                v-model="filterDate"
                :max ="today"
                no-title
                locale="ja"
                @input="showFilterDateSelector = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="5" class="ml-2">
            <ValidationProvider
              v-slot="{ errors }"
              name="4桁ナンバー"
              rules="car_number"
            >
              <v-text-field
                outlined
                dense
                label="4桁ナンバー"
                v-model="filterNumber"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="2">
            <v-btn class="ml-2 px-0" outlined small color="teal" :disabled="invalid" @click="getFilteredInOutRecords">検索</v-btn>
          </v-col>
        </v-row>
      </ValidationObserver>
      <v-divider class="my-0"></v-divider>
      <v-card-text class="mt-0 pa-3">
        <v-row v-if="!isLoading && records.length != 0" class="pb-3">
          <v-col v-for="record in records" :key="record.id" cols="12" lg="6" class="pb-0">
            <InOutRecord
              :record="record"
              @receiveRecord="sendRecord"
            />
          </v-col>
        </v-row>
        <v-row v-if="!isLoading && records.length == 0">
          <v-col cols="12" class="pa-5">
            該当する利用履歴がありません
          </v-col>
        </v-row>
        <v-row v-if="isLoading">
          <v-col cols="12" class="text-center my-10">
            <v-progress-circular
              :size="70"
              :width="7"
              color="grey"
              indeterminate
            ></v-progress-circular>
            <div class="mt-3">利用台数の多い駐車場では検索に少しお時間がかかります…</div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="my-0"></v-divider>
      <v-row no-gutters v-if="!isLoading && records.length != 0" justify="center" class="pt-2">
        <v-col cols="12">
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="7"
            @input="getInOutRecords"
          ></v-pagination>
        </v-col>
      </v-row>
      <v-card-title class="mt-3 py-1 px-3 blue-grey lighten-3">
        ▶︎手動で車両情報を入力
      </v-card-title>
      <v-row class="mx-2 my-0" justify="center">
        <v-col cols="12" lg="9">
          <small>駐車場利用がない場合や利用履歴が見つからない場合は手動で車両情報をご入力下さい。</small>
        </v-col>
      </v-row>
      <v-card-actions class="pt-0 pb-4">
        <v-spacer></v-spacer>
        <v-btn outlined @click="close">手動で入力</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment"
import InOutRecord from './InOutRecord'

export default {
  props: ["selectedRequestClientStore", "inOutRecordsDialog", "searchFrom"],
  components: {
    InOutRecord,
  },
  data: () => ({
    showFilterDateSelector: false,

    records: [],
    currentPage: 1,
    recordsPerPage: 10,
    filterDate: '',
    filterDateFormatted: '',
    filterNumber: '',
    totalPages: null,
    isLoading: false,

    selectedRecord: '',
  }),
  computed: {
    today () {
      return moment().format('yyyy-MM-DD')
    },
  },
  watch: {
    selectedRequestClientStore (value) {
      if (value && value.address) {
        this.currentPage = 1
        this.filterDate = moment().format('yyyy-MM-DD')
        this.filterNumber = ''
        this.getInOutRecords()
      }
    },
    filterDate (value) {
      if (value) { this.filterDateFormatted = moment(value).format('YY/MM/DD') }
    },
  },
  methods: {
    getFilteredInOutRecords () {
      this.currentPage = 1
      this.getInOutRecords()
    },
    async getInOutRecords () {
      this.isLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}in_out_records?from=${this.searchFrom}&page=${this.currentPage}&per=${this.recordsPerPage}&host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}&date=${this.filterDate}&car_number=${this.filterNumber}`
      console.log(url)
      await this.axios
        .get(url)
        .then(response => {
          this.records = response.data.records
          this.totalPages = response.data.total_pages
          this.$emit('applyParkedRecords', response.data.parked_records)
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `入庫情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
      this.isLoading = false
    },
    sendRecord (record) {
      this.$emit('applyRecord', record)
    },
    close () {
      this.$emit('closeDialog')
    }
  },
  mounted() {
    this.filterDate = moment().format('yyyy-MM-DD')
  },
}
</script>

<style scoped>
</style>