<template>
  <v-container>
    <v-card tile class="mb-1">
      <v-toolbar flat dense>
        <v-toolbar-title>フロア・エリア検索
        </v-toolbar-title>
      </v-toolbar>
      <v-container fill-height>
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-alert dense text type="info" :icon="false" class="mb-2">
              <small>こちらは、駐車中車両のフロア・エリア検索です。</small>
              <div><small>ナンバープレートまたは入庫時刻で駐車中の車両を検索できます。</small></div>
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6" class="py-0">
            <ValidationProvider
              v-slot="{ errors }"
              name="店舗名"
              rules="required"
            >
              <v-autocomplete
                :outlined="$store.getters.isAdminUser"
                dense
                label="店舗名"
                :items="request_client_stores"
                item-text="name"
                item-value="id"
                v-model="selectedRequestClientStore"
                return-object
                :disabled="!$store.getters.isAdminUser"
                :error-messages="errors"
              ></v-autocomplete>
            </ValidationProvider>
            </v-col>
        </v-row>
        <ValidationObserver slim v-slot="{ invalid }">
          <v-row v-if="selectedRequestClientStore">
            <v-col cols="12" class="py-0">
              <small>▽ 入庫日を選択してください。</small>
              <v-divider class="mt-0 mb-3"></v-divider>
            </v-col>
            <v-col md="4" cols="12" class="py-0">
              <v-menu
                v-model="showFilterDateSelector"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="入庫日"
                    rules="required"
                  >
                    <v-text-field
                      outlined
                      dense
                      v-model="filterDateFormatted"
                      label="入庫日"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="filterDate"
                  :max ="today"
                  no-title
                  locale="ja"
                  @input="showFilterDateSelector = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" class="py-0">
              <small>▽ ナンバープレートまたはおおよその入庫時刻を入力してください。※入庫時刻は前後1時間の車両を表示します。</small>
              <v-divider class="mt-0 mb-3"></v-divider>
            </v-col>
            <v-col md="4" cols="12" class="py-0">
              <v-radio-group v-model="searchType" row dense hide-details class="mt-0 mb-2">
                <v-radio label="4桁ナンバー" value="car_number"></v-radio>
                <v-radio label="入庫時刻" value="entrant_time"></v-radio>
              </v-radio-group>
              <template v-if="searchType === `car_number`">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="4桁ナンバー"
                  rules="required|car_number"
                >
                  <v-text-field
                    outlined
                    dense
                    label="4桁ナンバー"
                    v-model="filterNumber"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </template>
              <template v-else>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="入庫時間"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    dense
                    label="入庫時刻"
                    type="time"
                    v-model="filterEntrantTime"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </template>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-btn outlined small color="teal" class="mx-1 my-2" :disabled="invalid" @click="searchType === 'car_number' ? carNumberSearch() : entrantTimeSearch()">検索</v-btn>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-container>
    </v-card>
    <v-card v-if="selectedRequestClientStore" tile class="mt-2">
      <v-card-text class="mt-0 pa-3">
        <v-row v-if="!isLoading && paginatedRecords.length != 0" class="pb-3" style="max-height: 800px; overflow-y: auto;">
          <v-col v-for="record in paginatedRecords" :key="record.id" cols="12" sm="6" md="4" lg="3" class="pb-0">
            <v-card class="mx-auto" outlined>
              <v-hover v-slot="{ hover }">
                <div class="blur" :style="bgimage(record.imagePath)" @click="openShowImage(record.imagePath)">
                  <v-overlay absolute :value="hover">
                    <v-icon class="magnifier-icon">mdi-magnify</v-icon>
                  </v-overlay>
                </div>
              </v-hover>
              <v-card-title class="px-3 py-2">
                <small>
                  {{ formattedEntrantTime(record.entrantTime) }}<span class="pl-1">入庫</span>
                </small>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined small class="px-0" :disabled="!record.imageMapUrl || loadingRecordId === record.id || (loadingRecordId && loadingRecordId !== record.id)" :loading="loadingRecordId === record.inoutNo" @click="openShowMapImage(record)">マップ</v-btn>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="!isLoading && paginatedRecords.length == 0">
          <v-col cols="12" class="pa-5">
            該当する利用履歴がありません
          </v-col>
        </v-row>
        <v-row v-if="isLoading">
          <v-col cols="12" class="text-center my-10">
            <v-progress-circular
              :size="70"
              :width="7"
              color="grey"
              indeterminate
            ></v-progress-circular>
            <div class="mt-3">利用台数の多い駐車場では検索に少しお時間がかかります…</div>
          </v-col>
        </v-row>
        <v-row justify="center" class="pa-2">
          <v-pagination v-if="!isLoading && records.length > 0" v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="carImageDialog" max-width="800px">
      <div color="white" class="blur" :style="bgimage(carImage)"></div>

    </v-dialog>
    <v-dialog v-model="mapImageDialog" persistent max-width="800px">
      <v-card v-if="selectedRecord">
        <v-card-title>エリア: {{ stayAreaNameJp }} <span class="pl-2 text-caption">{{ stayAreaNameUk }}</span></v-card-title>
        <v-divider class="my-0"></v-divider>
        <v-card-text>
          <v-img contain :src="mapImagePath" class="white-background"></v-img>
        </v-card-text>
        <v-divider class="ma-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined @click="closeMapImageDialog">閉じる</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
  name: 'ParkingMaps',
  data () {
    return {
      // 店舗
      request_client_stores: [],
      selectedRequestClientStore: null,
      // 車両検索
      showFilterDateSelector: false,
      filterDate: '',
      filterDateFormatted: '',
      filterNumber: '',
      // 入庫時間検索
      filterEntrantTime: '',
      showTimeSearch: false,
      // 一覧
      records: [],
      page: 1,
      perPage: 10,
      totalPages: null,
      isLoading: false,
      // バリデーション
      searchType: 'car_number',
      isCarNumberValid: false,
      isEntrantTimeValid: false,
      // 画像拡大
      carImageDialog: false,
      carImage: '',
      imageKind: '',
      // 詳細
      mapImageDialog: false,
      selectedRecord: null,
      selectedRecordDetail: null,
      loadingRecordId: null
    }
  },
  computed: {
    today () {
      return moment().format('yyyy-MM-DD')
    },
    paginatedRecords() {
      const start = (this.page - 1) * this.perPage
      const end = start + this.perPage
      return this.records.slice(start, end)
    },
    pageCount() {
      return Math.ceil(this.records.length / this.perPage);
    },
    bgimage () {
      return (value) => {
        return `background: center / cover no-repeat url(${value});`
      }
    },
    formattedEntrantTime () {
      return (value) => {
        return value ? moment(value).format( "YY/MM/DD HH:mm:ss" ) : ''
      }
    },
    stayAreaNameJp () {
      return this.selectedRecordDetail && this.selectedRecordDetail.nameJp ? this.selectedRecordDetail.nameJp : this.selectedRecord.nameJp
    },
    stayAreaNameUk () {
      return this.selectedRecordDetail && this.selectedRecordDetail.nameUk ? this.selectedRecordDetail.nameUk : this.selectedRecord.nameUk
    },
    mapImagePath () {
      return this.selectedRecordDetail && this.selectedRecordDetail.imageMapUrl ? this.selectedRecordDetail.imageMapUrl : this.selectedRecord.imageMapUrl
    }
  },
  watch: {
    selectedRequestClientStore (value) {
      if (value && value.address) {
        this.page = 1
      }
    },
    filterDate (value) {
      if (value) { this.filterDateFormatted = moment(value).format('YY/MM/DD') }
    }
  },
  methods: {
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url, { params: { userId: 1} })
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
            this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
          }
        })
        .catch(err => {
          console.log(err)
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    carNumberSearch () {
      this.page = 1
      const url = `${process.env.VUE_APP_BASE_URL}search_parking_maps?host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}&date=${this.filterDate}&car_number=${this.filterNumber}`
      this.searchInOutRecords(url)
    },
    entrantTimeSearch () {
      this.page = 1
      const url = `${process.env.VUE_APP_BASE_URL}search_parking_maps?host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}&date=${this.filterDate}&search_time=${this.filterEntrantTime}`
      this.searchInOutRecords(url)
    },
    async searchInOutRecords (url) {
      this.isLoading = true
      await this.axios
        .get(url)
        .then(response => {
          this.records = response.data
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `入庫情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
      this.isLoading = false
    },
    async openShowMapImage (record) {
      this.loadingRecordId = record.inoutNo
      this.selectedRecord = record
      this.selectedRecordDetail = null
      const url = `${process.env.VUE_APP_BASE_URL}search_parking_maps/${record.inoutNo}?host=${this.selectedRequestClientStore.address}&port=${this.selectedRequestClientStore.port}`
      await this.axios
        .get(url)
        .then(response => {
          this.selectedRecordDetail = response.data
          this.mapImageDialog = true
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `入庫情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
        this.loadingRecordId = null
    },
    validateCarNumber () {
      this.$refs.carNumberObserver.validate().then((isValid) => {
        if (isValid) {
          this.carNumberSearch()
        }
      })
    },
    validateEntrantTime () {
      this.$refs.entrantTimeObserver.validate().then((isValid) => {
        if (isValid) {
          this.entrantTimeSearch()
        }
      })
    },
    openShowImage (imagePath) {
      this.carImage = imagePath
      this.carImageDialog = true
    },
    closeMapImageDialog () {
      this.selectedRecord = null
      this.selectedRecordDetail = null
      this.mapImageDialog = false
    }
  },
  mounted () {
    this.getRequestClientStores()
    this.filterDate = moment().format('yyyy-MM-DD')
  }
})
</script>
<style scoped>
.blur {
  position: relative;
  max-width: 100%;
  padding-bottom: 75%;
  background-size: cover;
  overflow: hidden;
}
.blur:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	background: inherit;
	background-clip: content-box;
	width: 100%;
	height: 100%;
	padding-bottom: 60%;
	filter: blur(5px);
}
.magnifier-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 36px;
  opacity: 0;
  transition: opacity 0.3s;
}
.blur:hover .magnifier-icon {
  opacity: 1;
}
.blur .on-hover { transition: opacity .4s ease-in-out; cursor: pointer; }
.on-hover { opacity: 1; }
.white-background {
  background-color: white;
}
</style>
