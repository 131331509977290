<template>
  <v-container>
    <v-data-table
      dense
      :headers="headers"
      :items="request_client_users"
      :search="search"
      :items-per-page="15"
      :loading="loading"
      loading-text="Loading... Please wait"
      disable-sort
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>ユーザー一覧</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                outlined
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                新規登録
              </v-btn>
            </template>
            <v-card :loading="isFormLoading">
              <ValidationObserver v-slot="{ invalid }" ref="observer">
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form ref="form">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="ユーザー名"
                            rules="required"
                          >
                            <v-text-field
                              dense
                              v-model="editedItem.name"
                              label="ユーザー名"
                              :error-messages="errors"
                              @input="onChange"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="権限"
                            rules="required"
                          >
                            <v-select
                              dense
                              label="権限"
                              item-text="roleName"
                              item-value="role"
                              :items="roleItems"
                              v-model="editedItem.role"
                              :error-messages="errors"
                              @input="resetNoticeMailFlg"
                              :disabled="editedIndex > -1"
                            ></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12">
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="メール"
                            rules="required|email"
                          >
                            <v-text-field
                              dense
                              v-model="editedItem.email"
                              label="メール"
                              :error-messages="errors"
                              :rules="[
                                () => isDisableAddress || 'このメールアドレスは利用できません',
                              ]"
                              @input="onChange"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col v-if="editedItem.role != `admin`" cols="12">
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="店舗名"
                            rules="required"
                          >
                            <v-autocomplete
                              dense
                              label="店舗名(検索して選択)"
                              item-text="name"
                              item-value="id"
                              :items="request_client_stores"
                              v-model="editedItem.request_client_store_id"
                              :error-messages="errors"
                              @input="onChange"
                            ></v-autocomplete>
                          </ValidationProvider>
                        </v-col>
                        <template v-if="editedItem.role == `admin`">
                          <v-col class="pt-0" cols="12">
                            <v-switch
                              v-model="editedItem.registration_mail_flg"
                              label="依頼登録を通知"
                              hide-details
                              class="my-0"
                              @click="onChange"
                            ></v-switch>
                            <v-spacer></v-spacer>
                          </v-col>
                          <v-col class="pt-0" cols="12">
                            <v-switch
                              v-model="editedItem.error_mail_flg"
                              label="依頼登録エラーを通知"
                              hide-details
                              class="my-0"
                              @click="onChange"
                            ></v-switch>
                            <v-spacer></v-spacer>
                          </v-col>
                        </template>
                        <v-col cols="12">
                          <v-switch
                            v-if="editedIndex > -1"
                            v-model="changePassword"
                            :label="`パスワードを変更`"
                            hide-details
                          ></v-switch>
                          <v-spacer></v-spacer>
                        </v-col>
                        <template v-if="editedIndex === -1 || changePassword">
                          <v-col cols="12" sm="6">
                            <ValidationProvider
                              v-slot="{ errors }"
                              name="パスワード"
                              rules="required|password_complex|no_full_width"
                              vid="password"
                            >
                              <v-text-field
                                dense
                                v-bind:type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                                prepend-icon="mdi-lock"
                                v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                v-model="editedItem.password"
                                label="パスワード"
                                :error-messages="errors"
                                autocomplete="off"
                                @input="onChange"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <ValidationProvider
                              v-slot="{ errors }"
                              name="パスワード再入力"
                              rules="required|password_complex|no_full_width|password_confirmed:@password"
                            >
                              <v-text-field
                                dense
                                v-bind:type="showPasswordConfirmation ? 'text' : 'password'"
                                @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                                prepend-icon="mdi-lock"
                                v-bind:append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                                v-model="editedItem.password_confirmation"
                                label="パスワード再入力"
                                :error-messages="errors"
                                autocomplete="off"
                                @input="onChange"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </template>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn small outlined color="blue-grey" @click="close">キャンセル</v-btn>
                  <v-btn small outlined color="primary" :disabled="invalid || !isDisableAddress || !isChanged" @click="save">{{ submitLabel }}</v-btn>
                </v-card-actions>
              </ValidationObserver>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDelete"
            max-width="500px"
            persistent
          >
            <v-card>
              <v-card-title class="text-h5">このユーザーを削除してよろしいですか？</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12">ID: {{ editedItem.id }}</v-col>
                    <v-col cols="12" sm="6">ユーザー名: {{ editedItem.name }}</v-col>
                    <v-col cols="12" sm="6">権限: {{ editedItem.role_name }}</v-col>
                    <v-col cols="12" sm="12">メール: {{ editedItem.email }}</v-col>
                    <v-col v-if="editedItem.role != `admin`" cols="12" sm="12">店舗名: {{ editedItem.store_name }}</v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small outlined color="blue-grey" @click="closeDelete">キャンセル</v-btn>
                <v-btn small outlined color="error" @click="deleteItemConfirm">削除</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="キーワード検索"
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn small outlined color="primary" class="mr-2" @click="editItem(item)">編集</v-btn>
        <v-btn small outlined color="error" @click="deleteItem(item)">削除</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import qs from 'qs'

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: '',
    loading: false,
    isFormLoading: false,
    isChanged: false,
    changePassword: false,
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'ユーザー名', align: 'start', value: 'name' },
      { text: 'メールアドレス', value: 'email' },
      { text: '権限', value: 'role_name' },
      { text: '店舗名', value: 'store_name' },
      { text: '操作', value: 'actions' },
    ],
    request_client_users: [],
    addresses: [],
    request_client_stores: [],
    roleItems: [
      { role: 'nomal', roleName: "一般" },
      { role: 'admin', roleName: "管理者" },
    ],
    showPassword : false,
    showPasswordConfirmation : false,
    editedIndex: -1,
    editedItem: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      role: '',
      request_client_store_id: '',
      registration_mail_flg: false,
      error_mail_flg: false,
    },
    defaultItem: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      role: '',
      request_client_store_id: '',
      registration_mail_flg: false,
      error_mail_flg: false,
    },
  }),
  watch: {
    changePassword (value) {
      !value || this.clearPassword()
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? '新規登録' : '編集'
    },
    submitLabel () {
      return this.editedIndex === -1 ? '登録' : '更新'
    },
    isDisableAddress () {
      if (this.editedIndex === -1) {
        return !this.addresses.includes(this.editedItem.email)
      } else {
        return !(this.addresses.includes(this.editedItem.email) && this.request_client_users[this.editedIndex].email != this.editedItem.email)
      }
    },
  },
  methods: {
    editItem (item) {
      this.editedIndex = this.request_client_users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.resetForm()
      this.dialog = true
    },
    deleteItem (item) {
      this.editedIndex = this.request_client_users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    resetNoticeMailFlg () {
      if (this.editedItem.role == 'admin') {
        this.editedItem.registration_mail_flg = false
        this.editedItem.error_mail_flg = false
      }
    },
    onChange() {
      this.isChanged = true
    },
    clearPassword () {
      this.editedItem.password = ''
      this.editedItem.password_confirmation = ''
    },
    async deleteItemConfirm () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_users/" + this.editedItem.id
      await this.axios.delete(url)
      .then(() => {
        this.$buefy.toast.open({
          message: `ユーザーを削除しました`,
          type: "is-info",
        })
        this.getRequestClientUsers()
      })
      .catch(err => {
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else {
          this.$buefy.toast.open({
            message: `ユーザー削除に失敗しました`,
            type: "is-danger",
          })
        }
      })
      this.closeDelete()
    },
    close () {
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      this.resetForm()
    },
    closeDelete () {
      this.dialogDelete = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      this.resetForm()
    },
    async save () {
      this.isFormLoading = true
      if (this.editedIndex > -1) {
        const url = process.env.VUE_APP_BASE_URL + "request_client_users/" + this.editedItem.id
        const data = {
          request_client_user: {
            name: this.editedItem.name,
            email: this.editedItem.email,
            role: this.editedItem.role,
            password: this.editedItem.password,
            password_confirmation: this.editedItem.password_confirmation,
            registration_mail_flg: this.editedItem.registration_mail_flg,
            error_mail_flg: this.editedItem.error_mail_flg,
          },
          request_client_store_id: this.editedItem.request_client_store_id
        }
        const options = {
          url: url,
          method: 'PUT',
          data: qs.stringify(data),
        }
        await this.axios(options)
        .then(() => {
          this.getRequestClientUsers()
          this.$buefy.toast.open({
            message: `ユーザー情報を更新しました`,
            type: "is-info",
          })
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `ユーザー情報更新に失敗しました`,
              type: "is-danger",
            })
          }
        })
      } else {
        const url = process.env.VUE_APP_BASE_URL + "request_client_users"
        const data = {
          request_client_user: {
            name: this.editedItem.name,
            email: this.editedItem.email,
            role: this.editedItem.role,
            password: this.editedItem.password,
            password_confirmation: this.editedItem.password_confirmation,
            registration_mail_flg: this.editedItem.registration_mail_flg,
            error_mail_flg: this.editedItem.error_mail_flg,
          },
          request_client_store_id: this.editedItem.request_client_store_id
        }
        const options = {
          url: url,
          method: 'POST',
          data: qs.stringify(data),
        }
        await this.axios(options)
        .then(() => {
          this.$buefy.toast.open({
            message: `ユーザーを新規登録しました`,
            type: "is-info",
          })
          this.getRequestClientUsers()
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `ユーザー新規登録に失敗しました`,
              type: "is-danger",
            })
          }
        })
      }
      this.isFormLoading = false
      this.close()
    },
    getRequestClientUsers() {
      const url = process.env.VUE_APP_BASE_URL + "request_client_users"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_users = response.data
          this.addresses = this.request_client_users.map(request_client_user => request_client_user.email)
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `ユーザーデータ取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    getRequestClientStores() {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    resetForm() {
      if (this.$refs.form && this.$refs.observer) {
        this.$refs.form.resetValidation()
        this.$refs.observer.reset()
        this.isChanged = false
        this.showPassword = false
        this.showPasswordConfirmation = false
      }
    }
  },
  mounted() {
    this.loading = true
    this.getRequestClientUsers()
    this.getRequestClientStores()
    this.loading = false
  },
}
</script>

<style scoped>
</style>
