<template>
  <v-container>
    <v-card flat>
      <v-toolbar flat>
        <v-toolbar-title>大型バスアラート機能 登録内容変更</v-toolbar-title>
      </v-toolbar>

      <!-- 登録フォーム -->
      <ValidationObserver v-slot="{ invalid }">
        <v-container>
          <template>

            <v-row>
              <v-col cols="12" lg="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="店舗名"
                  rules="required"
                >
                  <v-autocomplete
                    :outlined="$store.getters.isAdminUser"
                    dense
                    label="店舗名"
                    :items="request_client_stores"
                    item-text="name"
                    item-value="id"
                    v-model="selectedRequestClientStore"
                    return-object
                    disabled
                    :error-messages="errors"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="py-0">
                <small>大型バスアラート機能 登録内容</small>
                <v-divider class="mt-0 mb-3"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <small>▽ メールアドレス</small>
              </v-col>
              <v-col cols="12" lg="6" class="pt-1">
                <v-text-field
                  v-model="email"
                  dense
                  outlined
                  :rules="[
                      () => !!email || '入力は必須です',
                      () => !!email.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{2,}$/) || 'メールアドレス形式で入力してください',
                    ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <small>▽ 件名 <span class="red--text"> ※設定しない場合は、「大型バスが入庫しました。」がアラートメールの件名に表示されます。</span></small>
              </v-col>
              <v-col cols="12" class="pt-1">
                <v-text-field v-model="subject" dense outlined hide-details></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <small>▽ 本文 <span class="red--text"> ※設定しない場合は、「大型バスが入庫しました。」がアラートメールの本文に表示されます。</span></small>
              </v-col>
              <v-col cols="12" class="pt-1">
                <v-textarea v-model="message" dense outlined hide-details></v-textarea>
              </v-col>
            </v-row>

          </template>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined color="brue-gray" class="mr-2" to="/">戻る</v-btn>
          <v-btn small outlined color="primary" :disabled="invalid || !isValidEmail(email)" @click.stop="openDialog">変更内容確認</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </ValidationObserver>

      <v-dialog
        v-model="dialog"
        max-width="500px"
        scrollable
      >
        <v-card>
          <v-card-title>変更内容確認</v-card-title>
          <v-divider class="my-0"></v-divider>
          <v-card-text>
            <v-row>
              <small class="my-5">以下の内容で更新します。よろしいですか？</small>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="店舗名"
                  :value="selectedRequestClientStore ? selectedRequestClientStore.name : ''"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="メールアドレス"
                  :value="email"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="件名"
                  :value="subject || '大型バスが入庫しました。'"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="本文"
                  :value="message || '大型バスが入庫しました。'"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ma-0"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small outlined color="blue-grey" @click="dialog = false">修正する</v-btn>
            <v-btn small outlined color="primary" @click="save" :loading="isLoading">{{ isEdit ? "更新" : "登録" }}する</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-card>
  </v-container>
</template>
<script>
import qs from 'qs'

export default {
  name: 'newAlertNotifications',
  data() {
    return {
      request_client_stores: [],
      selectedRequestClientStore: null,

      email: '',
      subject: '',
      message: '',

      // 編集画面用
      editItem: null,
      isEdit: false,
      isLoading: false,
      originalRequestId: null,

      dialog: false
    }
  },
  computed: {
  },
  methods: {
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      console.log(url)
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          this.setOriginalRequest()
          if (!this.$store.getters.isAdminUser) {
            this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
          }
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    setOriginalRequest() {
      console.log("スタート");
      const url = `${process.env.VUE_APP_BASE_URL}alert_notifications/${this.$route.params.id}?host=${this.$route.params.selectedRequestClientStore.address}&port=${this.$route.params.selectedRequestClientStore.port}`;
      console.log(url);
      this.axios.get(url)
        .then(response => {
          this.isEdit = true;
          this.selectedRequestClientStore = this.$route.params.selectedRequestClientStore;
          const selectedAlertNotification = this.$route.params.alertNotification;

          this.editAlertNotification = response.data.alert_notification;

          // 取得した通知の詳細データで初期値を上書き
          this.email = selectedAlertNotification.email;
          this.subject = selectedAlertNotification.subject;
          this.message = selectedAlertNotification.message;

        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    openDialog () {
      if (this.email.length !== 0) {
        this.dialog = true
      } else {
        this.$buefy.toast.open({
          message: `メールアドレスを入力してください`,
          type: "is-danger",
        })
      }
    },
    isValidEmail(email) {
      const regex = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{2,}$/;
      return regex.test(email);
    },
    async save() {
      this.isLoading = true
      const url = `${process.env.VUE_APP_BASE_URL}alert_notifications/${this.$route.params.id}`
      let data = {
        alert_notification: {
          email: this.email,
          subject: this.subject || '大型バスが入庫しました。',
          message: this.message || '大型バスが入庫しました。',
        },
        request_client_store_id: this.selectedRequestClientStore.id,
      }
      const options = {
        url: url,
        method: 'PUT',
        data: qs.stringify(data),
      }
      await this.axios(options)
      .then(() => {
        this.$router.push({ path: "/alert-notifications" })
        this.$buefy.toast.open({
          message: `アラート通知を${this.isEdit ? "更新" : "登録"}しました`,
          type: "is-info"
        })
      })
      .catch(err => {
        console.log(err.response)
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else if (err.response && err.response.status == 406) {
          this.$buefy.toast.open({
            message: `staging環境もしくはローカル開発環境から本番環境への登録・更新操作は許可されていません`,
            type: "is-danger",
          })
        } else if (err.response && err.response.status == 409) {
          this.$buefy.toast.open({
            message: `この通知の内容はすでに登録されています`,
            type: "is-danger",
          })
        } else {
          this.$buefy.toast.open({
            message: `アラート通知の更新に失敗しました`,
            type: "is-danger",
          })
        }
        this.isLoading = false
        this.dialog = false
      })
    },
  },
  mounted() {
    this.getRequestClientStores()
  },
}
</script>
<style>
</style>
