<template>
  <v-container>
    <v-card flat>
      <v-toolbar flat>
        <v-toolbar-title>大型バスアラート機能 通知先登録</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="pt-0">
              <v-alert dense text type="info" :icon="false" class="mb-2">
                <small>
                  こちらは大型バスが来たときにアラートメールで通知する機能です。<br>
                  以下のフォームに通知先のメールアドレスをご入力ください。メールアドレスは追加できます。<br>
                  件名と本文はオプションです。<br>
                </small>
              </v-alert>
            </v-col>
        </v-row>
      </v-card-text>

      <!-- 登録フォーム -->
      <ValidationObserver v-slot="{ invalid }">
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="6" class="py-0">
              <ValidationProvider
                  v-slot="{ errors }"
                  name="店舗名"
                  rules="required"
                >
                <v-autocomplete
                  :outlined="$store.getters.isAdminUser"
                  dense
                  label="店舗名"
                  :items="request_client_stores"
                  item-text="name"
                  item-value="id"
                  v-model="selectedRequestClientStore"
                  return-object
                  :disabled="!$store.getters.isAdminUser"
                  :error-messages="errors"
                ></v-autocomplete>
            </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
  
        <template v-if="selectedRequestClientStore">
          <v-card-text>
            <v-row>
              <v-col class="py-0">
                <small>▽メールアドレス<span class="red--text">(必須)</span></small>
              </v-col>
            </v-row>
            <v-row v-for="(email, index) in emails" :key="index">
              <v-col cols="6" class="py-0">
                <v-text-field
                  v-model="emails[index]"
                  outlined
                  dense
                  type="email"
                  :rules="[
                      () => !!email || '入力は必須です',
                      () => !!email.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{2,}$/) || 'メールアドレス形式で入力してください',
                    ]"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn small outlined color="red" @click="removeEmailField(index)" :disabled="emails.length === 1">削除</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-btn small outlined color="primary" @click="addEmailField">追加</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0 mt-10">
                <small>▽ 件名<span class="red--text"> ※設定しない場合は、「大型バスが入庫しました。」がアラートメールの件名に表示されます。</span></small>
                <v-text-field v-model="subject" dense outlined hide-details></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <small>▽ 本文<span class="red--text"> ※設定しない場合は、「大型バスが入庫しました。」がアラートメールの本文に表示されます。</span></small>
                <v-textarea v-model="message" dense outlined hide-details></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
  
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                small
                outlined
                color="brue-gray"
                class="mr-2" to="/"
              >戻る
              </v-btn>
              <v-btn
                small
                outlined color="primary"
                :disabled="invalid || !emails.every(email => isValidEmail(email))"
                @click.stop="dialog = true"
              >登録内容確認
              </v-btn>
              <v-spacer></v-spacer>
          </v-card-actions>
        </template>
      </ValidationObserver>

      <!-- 確認フォーム -->
      <v-dialog v-model="dialog" max-width="500px" scrollable>
        <v-card>
          <v-card-title>登録内容確認</v-card-title>
          <v-divider class="my-0"></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <small class="my-5">以下の内容で登録します。よろしいですか？</small>
                <v-col cols="12">
                  <v-subheader><small>メールアドレス</small></v-subheader>
                  <v-card outlined>
                    <v-list>
                      <v-list-item v-for="(email, index) in emails" :key="index">
                        <v-list-item-content>{{ email }}</v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="件名"
                    :value="subject || '大型バスが入庫しました。'"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="本文"
                    :value="message || '大型バスが入庫しました。'"
                    outlined
                    readonly
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn 
                small
                outlined
                color="brue-gray"
                class="mr-2 mb-5"
                @click="dialog = false"
              >
                修正する
              </v-btn>
              <v-btn
                small
                outlined
                color="primary"
                class="mb-5"
                :disabled="!emails.every(email => isValidEmail(email))"
                @click="submitForm"
              >
                登録する
              </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-card>
  </v-container>
</template>
<script>
export default {
  name: 'newAlertNotifications',
  data: () => ({
    selectedRequestClientStore: null,
    request_client_stores: [],
    errors: [],

    emails: [''],
    subject: '',
    message: '',
    alertinfo_id: 43, // 固定値を設定
    dialog: false,
  }),
  methods: {
    validateUserAccess() {
      if (!this.$store.getters.isAdminUser && !this.$store.getters.isAlertNotificationFlagged) {
        this.$router.push({ path: '/' });
      }
    },
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      console.log(url)
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
          if (!this.$store.getters.isAdminUser) {
            this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
          }
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    isValidEmail(email) {
      const regex = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{2,}$/;
      return regex.test(email);
    },
    async submitForm() {
      try {
        const url = process.env.VUE_APP_BASE_URL + 'alert_notifications';
        const validEmails = Array.from(new Set(this.emails.filter(email => email)));
        const alertNotifications = validEmails.map(email => ({
          alert_notification: {
            email: email,
            subject: this.subject || '大型バスが入庫しました。',
            message: this.message || '大型バスが入庫しました。',
            alertinfo_id: this.alertinfo_id,
          },
          request_client_store_id: this.selectedRequestClientStore.id,
        }))
        for (const alertNotification of alertNotifications) {
          await this.axios.post(url, alertNotification)
        }
        this.$router.push({path: "/alert-notifications"})
        this.$buefy.toast.open({
          message: "アラート通知の登録に成功しました",
          type: "is-success"
        })
      } catch (err) {
        console.log(err.response)
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: "is-danger",
          })
          this.$store.commit('clearUser')
          this.$router.push({path: "/login"})
        } else if (err.response && err.response.status == 406) {
          this.$buefy.toast.open({
            message: `staging環境もしくはローカル開発環境から本番環境への登録・更新操作は許可されていません`,
            type: "is-danger",
          })
        } else {
          this.$buefy.toast.open({
            message: `アラート通知の登録に失敗しました`,
            type: "is-danger",
          })
        }
        this.dialog = false;
      }
    },
    addEmailField() {
      this.emails.push('')
    },
    removeEmailField(index) {
      if (this.emails.length > 1) {
        this.emails.splice(index, 1);
      }
    },
  },
  mounted() {
    this.validateUserAccess()
    this.getRequestClientStores()
  },
}
</script>
<style>
</style>
