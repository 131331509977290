<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import {
  Chart,
  Title,
  Tooltip,
  Legend,
  SubTitle,
  BarElement,
  CategoryScale,
  LineController,
  LineElement,
  LinearScale,
  PointElement
} from 'chart.js'

Chart.register(Title, Tooltip, Legend, SubTitle, BarElement, CategoryScale, LineController, LineElement, LinearScale, PointElement)

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 200
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { '' }
    },
    plugins: {
      type: Array,
      default: () => ['']
    },
    chartData: {
      type: Object,
      default: () => { '' }
    },
    chartOptions: {
      type: Object,
      default: () => { '' }
    }
  }
}
</script>

<style>

</style>