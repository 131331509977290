<template>
  <div></div>
</template>

<script>
export default {
  data: () => ({
    normalizedFrequencyMinutes: 5, // 5分おきにfront側のバージョンアップをチェック
  }),
  computed: {
    normalizedUrl () {
      return location.origin + this.$router.resolve({name: 'home'}).href
    },
  },
  methods: {
    fetchServer () {
      fetch(this.normalizedUrl)
        .then(res => {
          if (res.status !== 200) {
            throw Error('Cannot access server.')
          }
          return res.text()
        })
        .then(html => {
          const hash = this.createHash(html)
          this.judgeHash(hash)
        })
        .catch(err => console.log(err))
    },
    createHash (str) {
      const len = str.length
      let hash = 0
      if (len === 0) return hash
      let i
      for (i = 0; i < len; i++) {
        hash = ((hash << 5) - hash) + str.charCodeAt(i)
        hash |= 0 // Convert to 32bit integer
      }
      return hash
    },
    judgeHash(hash) {
      let previousHash = JSON.parse(localStorage.getItem("request_app_ui_hash"))
      if (!previousHash || previousHash !== hash) {
        localStorage.setItem("request_app_ui_hash", JSON.stringify(hash))
        location.reload(true)
        return
      }
    },
  },
  mounted () {
    this.fetchServer()
    setInterval(this.fetchServer, 1000 * 60 * this.normalizedFrequencyMinutes)
  }
}
</script>

<style scoped>
</style>