<template>
  <v-container>
    <v-card width="400px" class="mx-auto my-5">
      <v-row class="justify-center">
        <v-card-title >
          <h1 class="display-1">ログイン</h1>
        </v-card-title>
      </v-row>
      <v-card-text>
        <ValidationObserver v-slot="ObserverProps">
          <v-form @submit.prevent="login">
            <ValidationProvider
              rules="required|email"
              v-slot="{ errors }"
              name="メールアドレス"
            >
              <v-text-field
                type="email"
                prepend-icon="mdi-email-outline"
                label="メールアドレス"
                v-model="email"
                :error-messages="errors"
                autocomplete="on"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="パスワード"
              rules="required"
            >
              <v-text-field
                v-bind:type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                prepend-icon="mdi-lock"
                v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                label="パスワード"
                v-model="password"
                :error-messages="errors"
                autocomplete="on"
              />
            </ValidationProvider>
            <small v-if="validateAlert" class="red--text font-weight-bold">
              {{ vaildMessage }}
            </small>
            <v-row class="justify-end">
              <v-card-actions>
                <v-btn type="submit" class="info" :disabled="ObserverProps.invalid || !ObserverProps.validated">ログイン</v-btn>
              </v-card-actions>
            </v-row>
          </v-form>
          <v-alert
            v-if="passwordInvalid"
            v-model="validateAlert"
            dense
            type="error"
            dismissible
            class="ma-2"
          >
            <small>パスワードの更新が完了しておらず、ログインできなくなってしまった場合は、
            ログイン時に使用しているメールアドレスを記載の上
            <a :href="generateMailtoLink">「request-pass-change@pitdesign.jp」</a>までお問い合わせください。</small>
          </v-alert>
        </ValidationObserver>
      </v-card-text>
    </v-card>
    <BrowserVersionAlert/>
  </v-container>
</template>

<script>
import BrowserVersionAlert from '../../components/BrowserVersionAlert.vue'

export default {
  name: 'Login',
  components: { BrowserVersionAlert },
  data: () => ({
    showPassword : false,
    email: "",
    password: "",
    validateAlert: false,
    passwordInvalid: false
  }),
  computed: {
    noFullWidthVaild () {
      return !/[^\u0020-\u007E]/.test(this.password)
    },
    hasNumber () {
      return /[0-9]/.test(this.password)
    },
    hasLowerCase () {
      return /[a-z]/.test(this.password)
    },
    hasUpperCase () {
      return /[A-Z]/.test(this.password)
    },
    lengthVaild () {
      return this.password.length >= 8 && this.password.length <= 32
    },
    hasVaildCharacters () {
      return /^[0-9a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~]+$/.test(this.password)
    },
    passwordVaild () {
      if (!this.password) {
        return false
      }
      return this.noFullWidthVaild && this.hasNumber && (this.hasLowerCase || this.hasUpperCase) && this.lengthVaild && this.hasVaildCharacters
    },
    vaildMessage () {
      if (this.password) {
        if (!this.noFullWidthVaild) {
          return 'パスワードには全角文字を含めないでください。';
        }
        if (!this.lengthVaild) {
          return 'パスワードは8文字以上、32文字以下にしてください。';
        }
        if (!this.hasNumber) {
          return 'パスワードには少なくとも1つの数字を含めてください。';
        }
        if (!this.hasLowerCase && !this.hasUpperCase) {
          return 'パスワードには少なくとも1つのアルファベットを含めてください。';
        }
        if (!this.hasVaildCharacters) {
          return 'パスワードには許可された記号のみを使用してください。';
        }
        return null
      } else {
        return null
      }
    },
    generateMailtoLink () {
      const user = 'request-pass-change';
      const domain = 'pitdesign.jp';
      return `mailto:${user}@${domain}?subject=【依頼ツール】パスワード変更のお問い合わせ&body=ログイン時に使用しているメールアドレスを記載してください`
    }
  },
  methods: {
    login() {
      this.validateAlert = false
      this.passwordInvalid = false
      if (!this.passwordVaild) {
        this.validateAlert = true
        this.passwordInvalid = true
        return
      }
      this.$store
      .dispatch("signIn", {
        email: this.email,
        password: this.password
      })
      .then(() => {
        this.$router.push({path: "/"})
        this.$buefy.toast.open({
          message: `ログインしました`,
          type: "is-info",
        })
      })
      .catch(() => {
        this.$buefy.toast.open({
          message: `メールアドレスかパスワードが違います`,
          type: "is-danger",
        })
      })
    }
  },
}
</script>

<style scoped>
</style>