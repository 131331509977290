<template>
  <v-navigation-drawer
    v-if="!this.$route.path.match('/login')"
    class="hidden-md-and-down"
    app
  >
    <MenuContents/>
  </v-navigation-drawer>
</template>

<script>
import MenuContents from './MenuContents.vue'

export default {
  components: { MenuContents },
}
</script>
