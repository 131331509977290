<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      temporary
      app
    >
      <MenuContents/>
    </v-navigation-drawer>

    <v-app-bar
      app
      dark
      v-bind:style="{ background: environmentCheck }"
    >
      <router-link to="/">
        <div v-if="this.$route.path.match('/login')">
          <h4 class="sitename font-weight-black">
            依頼ツール<small class="font-weight-light ml-1">{{ VERSION }}</small>
          </h4>
        </div>
        <div v-else class="d-flex align-center">
          <v-img
            alt="Smartpark Logo"
            :src="productLogo"
            class="shrink mr-2"
            contain
            transition="scale-transition"
            width="40"
          />
          <h4 class="sitename font-weight-black">
            <div class="caption">{{ productName }}</div>依頼ツール<small class="font-weight-light ml-1">{{ VERSION }}</small>
          </h4>
        </div>
      </router-link>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!this.$route.path.match('/login')"
        small
        outlined
        class="font-weight-black hidden-lg-and-up"
        @click.stop="drawer = !drawer"
      >
        menu
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { VERSION } from '../const/version.js'
import MenuContents from './MenuContents.vue'

export default {
  components: { MenuContents },
  data: () => ({
    environmentCheck: "#343a40",
    drawer: false,
  }),
  computed: {
    VERSION () {
      return VERSION
    },
    productName () {
      return this.$store.getters.isAuthenticated && this.$store.getters.productName ? this.$store.getters.productName : "スマートパーク"
    },
    productLogo () {
      return this.$store.getters.isAuthenticated && this.$store.getters.productName === "イージーパーク" ? require('../assets/icon_ez.png') : require('../assets/icon_smp.png')
    }
  },
  methods: {
  },
  mounted() {
    if (process.env.VUE_APP_BASE_URL != "https://commons-api.sub.pitdesign.jp/api/request_app_client/") {
      this.environmentCheck = "#17a2b8"
    }
  },
}
</script>
<style scoped>
a {
  text-decoration : none;
}
.sitename {
  color: white;
}
</style>