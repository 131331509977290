<template>
  <v-container>
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>ダッシュボード</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="12" lg="4" class="pt-0 pb-md-0 pb-4">
            <v-autocomplete
              :outlined="$store.getters.isAdminUser"
              dense
              label="店舗名"
              :items="request_client_stores"
              item-text="name"
              item-value="id"
              v-model="selectedRequestClientStore"
              return-object
              hide-details
              :disabled="!$store.getters.isAdminUser || isLoading"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      <v-container fill-height>
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-end align-center">
              <div v-if="dashboardData && !isLoading">
                <small class="mr-2">最終更新時刻<span class="mx-4">{{ formattedDateTime }}</span></small>
                <v-btn tile outlined small @click="updateTime"><v-icon>mdi-refresh</v-icon></v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
        <template v-if="dashboardData && !isLoading">
          <v-row>
            <v-col md="4" cols="12">
              <v-card tile height="150px" class="d-flex flex-column align-center justify-center">
                <div class="text-md-h4 text-h6 font-weight-bold">{{ formattedDate }}</div>
                <div class="text-subtitle-1">来場日</div>
              </v-card>
            </v-col>
            <v-col md="4" cols="12">
              <v-card tile height="150px" class="d-flex flex-column align-center justify-center">
                <div class="text-md-h4 text-h6 font-weight-bold">
                  {{ dashboardData.daliyEntries }}
                  <span class="pl-1 text-subtitle-1 font-weight-bold">台</span>
                </div>
                <div class="text-subtitle-1">本日の入庫車両台数</div>
              </v-card>
            </v-col>
            <v-col md="4" cols="12">
              <v-card tile height="150px" class="d-flex flex-column align-center justify-center">
                <div class="text-md-h4 text-h6 font-weight-bold">
                  {{ dashboardData.daliyExits }}
                  <span class="pl-1 text-subtitle-1 font-weight-bold">台</span>
                </div>
                <div class="text-subtitle-1">本日の出庫車両台数</div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="font-weight-bold">本日の間口状況(入庫/出庫)</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-for="(item, i) in dashboardData.laneEntryExits" :key="i" md="3" cols="12">
              <v-card tile height="100px" class="d-flex flex-column align-center justify-center">
                <div class="text-subtitle-1">{{ item.lane_name }}</div>
                <div class="text-h6 font-weight-bold">
                  {{ item.entry_count }}
                  <span class="px-1 text-subtitle-1 font-weight-bold">/</span>
                  {{ item.exit_count }}
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="font-weight-bold">間口情報</div>
            </v-col>
            <v-col cols="12">
              <v-img :src="laneImage"></v-img>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <v-container v-if="isLoading" class="text-center my-10">
        <v-progress-circular
          :size="70"
          :width="7"
          color="grey"
          indeterminate
        ></v-progress-circular>
      </v-container>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn small outlined color="blue-grey" to="/">戻る</v-btn>
      </v-toolbar>
    </v-card>
  </v-container>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    name: 'Dashboard',
    data: () => ({
      date: new Date(),
      selectedRequestClientStore: null,
      request_client_stores: [],
      dashboardData: null,
      isLoading: false
    }),
    computed: {
      formattedDate () {
        const year = this.date.getFullYear()
        const month = this.date.getMonth() + 1
        const day = this.date.getDate()
        return `${year}年${month}月${day}日`
      },
      formattedDateTime () {
        const hours = this.date.getHours().toString().padStart(2, '0')
        const minutes = this.date.getMinutes().toString().padStart(2, '0')
        const seconds = this.date.getSeconds().toString().padStart(2, '0')
        return `${this.formattedDate}` + ' ' + `${hours}:${minutes}:${seconds}`
      },
      comparisonClass () {
        return {
          'pl-2 text-caption font-weight-bold': true,
          'green--text text--light-3': this.dashboardData && (this.dashboardData.todayInCount - this.dashboardData.yesterdayInCount) >= 0,
          'red--text text--light-3': this.dashboardData && (this.dashboardData.todayInCount - this.dashboardData.yesterdayInCount) < 0
        }
      },
      vehicleCountDifference () {
        return (value) => {
          const todayInCount = value.todayInCount || 0
          const yesterdayInCount = value.yesterdayInCount || 0
          if (yesterdayInCount === 0) {
            return todayInCount === 0 ? 0.0 : 100.0
          } else {
            const rate = (todayInCount - yesterdayInCount) / yesterdayInCount * 100
            const formattedRate = rate.toFixed(2)
            return parseFloat(formattedRate)
          }
        }
      },
      comparisonColor() {
        const difference = this.vehicleCountDifference(this.dashboardData)
        return difference >= 0 ? 'green light-3' : 'red light-3'
      },
      comparisonIcon() {
        const difference = this.vehicleCountDifference(this.dashboardData)
        return difference >= 0 ? 'top-right-thin' : 'bottom-right-thin'
      },
      laneImage () {
        return (this.$store.getters.isAeonYonoAdmin || this.$store.getters.isAdminUser) ? 'https://smapa-phase3.s3.ap-northeast-1.amazonaws.com/lane_images/aeonyono_lane_image.jpg' : ''
      }
    },
    watch: {
      selectedRequestClientStore (value) {
        if (value && value.address) {
          this.getDashBoardData()
        }
      },
    },
    methods: {
      updateTime() {
        this.date = new Date()
        this.getDashBoardData()
      },
      getRequestClientStores () {
        const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
        this.axios
          .get(url)
          .then(response => {
            this.request_client_stores = response.data
            if (!this.$store.getters.isAdminUser) {
              this.selectedRequestClientStore = this.request_client_stores.find(request_client_store => request_client_store.id == this.$store.getters.userRequestClientStoreId)
            }
          })
          .catch(err => {
            this.handleError(err, '店舗情報の取得に失敗しました。')
          })
      },
      async getDashBoardData () {
        this.isLoading = true
        this.dashboardData = null
        const params = `store_id=${this.selectedRequestClientStore.id}`
        const url = process.env.VUE_APP_BASE_URL + "dashboards?" + params
        try {
          const response = await this.axios.get(url)
          console.log(response.data)
          this.dashboardData = response.data
        } catch (err) {
          console.log(err)
          this.handleError(err, '入出場データの取得に失敗しました。')
        } finally {
          this.isLoading = false
        }
      },
      handleError (err, message) {
        if (this.$route.path.match('/login')) {
          return
        }
        if (err.response && err.response.status == 401) {
          this.$buefy.toast.open({
            message: `認証に失敗しました。ログインし直してください。`,
            type: 'is-denger'
          })
          this.$store.commit('clearUser')
          this.$router.push({ path: '/login' })
        } else {
          this.$buefy.toast.open({
            message,
            type: 'is-danger'
          })
        }
      }
    },
    mounted () {
      this.getRequestClientStores()
    }
})
</script>
<style scoped>

</style>