<template>
  <v-container>
    <v-card max-width="500px" class="mx-auto">
      <v-card-text>
        <v-container>
          <ValidationObserver v-slot="{ invalid }">
            <v-form>
              <v-card-title dense class="pt-0">
                <span class="text-h5">アカウント情報変更</span>
              </v-card-title>
              <v-row class="mt-5">
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="ユーザー名"
                    rules="required"
                  >
                    <v-text-field
                      dense
                      label="ユーザー名"
                      v-model="account.name"
                      :error-messages="errors"
                      @input="onChange"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="メールアドレス"
                    rules="required|email"
                  >
                    <v-text-field
                      dense
                      label="メールアドレス"
                      v-model="account.email"
                      :error-messages="errors"
                      @input="onChange"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-switch
                  v-model="changePassword"
                  :label="`パスワードを変更`"
                ></v-switch>
                <v-spacer></v-spacer>
              </v-row>
              <v-row v-if="changePassword">
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="現在のパスワード"
                    rules="required"
                  >
                    <v-text-field
                      dense
                      v-bind:type="showOldPassword ? 'text' : 'password'"
                      @click:append="showOldPassword = !showOldPassword"
                      prepend-icon="mdi-lock"
                      v-bind:append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      label="現在のパスワード"
                      v-model="oldPassword"
                      :error-messages="errors"
                      @input="onChange"
                      autocomplete="on"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="新しいパスワード"
                    rules="required|password_complex|no_full_width"
                    vid="password"
                  >
                    <v-text-field
                      dense
                      v-bind:type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      prepend-icon="mdi-lock"
                      v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      label="新しいパスワード"
                      v-model="password"
                      :error-messages="errors"
                      @input="onChange"
                      autocomplete="off"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="パスワード再入力"
                    rules="required|password_complex|no_full_width|password_confirmed:@password"
                  >
                    <v-text-field
                      dense
                      v-bind:type="showPasswordConfirmation ? 'text' : 'password'"
                      @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                      prepend-icon="mdi-lock"
                      v-bind:append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                      label="パスワード再入力"
                      v-model="passwordConfirmation"
                      :error-messages="errors"
                      @input="onChange"
                      autocomplete="off"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn small outlined color="brue-gray" class="mr-3" to="/">戻る</v-btn>
                <v-btn small outlined color="primary" @click="save" :disabled="(!isChanged || invalid)">更新</v-btn>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import qs from 'qs'

export default {
  data: () => ({
    isChanged: false,
    changePassword: false,
    account: {},
    oldPassword: '',
    password: '',
    passwordConfirmation: '',
    showOldPassword : false,
    showPassword : false,
    showPasswordConfirmation : false,
  }),
  watch: {
    changePassword (value) {
      !value || this.clearPassword()
    }
  },
  methods: {
    onChange() {
      this.isChanged = true
    },
    clearPassword () {
      this.oldPassword = ''
      this.password = ''
      this.passwordConfirmation = ''
    },
    getAccount () {
      const url = process.env.VUE_APP_BASE_URL + "accounts/" + this.$store.getters.requestClientUserId
      this.axios
        .get(url)
        .then(response => {
          this.account = response.data
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            console.log(err.response.data)
          }
        })
    },
    async save () {
      const url = process.env.VUE_APP_BASE_URL + "accounts/" + this.account.id
      let data = {
        request_client_user: {
          name: this.account.name,
          email: this.account.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        },
        change_password: this.changePassword,
        old_password: this.oldPassword,
      }
      const options = {
        url: url,
        method: 'PUT',
        data: qs.stringify(data),
      }
      await this.axios(options)
        .then(() => {
          this.getAccount()
          this.isChanged = false
          this.changePassword = false
          this.$buefy.toast.open({
            message: `ユーザー情報を更新しました`,
            type: "is-info",
          })
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `現在のパスワードが間違っています`,
              type: "is-danger",
            })
          } else if (err.response && err.response.status == 400) {
            this.$buefy.toast.open({
              message: `現在のパスワードを入力してください`,
              type: "is-danger",
            })
          } else if (err.response && err.response.status == 409) {
            this.$buefy.toast.open({
              message: `既に使用されているメールアドレスです`,
              type: "is-danger",
            })
          } else {
            this.$buefy.toast.open({
              message: `ユーザー情報更新に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
  },
  mounted() {
    this.getAccount()
  },
}
</script>

<style scoped>
</style>